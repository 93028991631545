import React from 'react';
import LandingPage from '@layouts/LandingPage';
import { Navigate } from 'react-router-dom';
import MainLayout from '@layouts/MainLayout/MainLayout';
import SearchPoDPage from '@pages/SearchPoDPage';
import CheckoutPage from '@pages/CheckoutPage';
import StationDetailPage from '@pages/StationDetail';
import StripeForm from '@pages/StripeForm';
import StatusPage from '@pages/StatusPage';
import PinActivationPage from '@pages/PinActivationPage';
import InstructionsPage from '@pages/InstructionsPage';

const routes = [
  {
    path: 'app',
    element: <MainLayout />,
    children: [
      // { path: 'search', element: <SearchPoDPage /> },
      { path: 'instructions', element: <InstructionsPage /> },
      { path: 'stations/:deviceId', element: <StationDetailPage /> },
      {
        path: 'stations/:deviceId/checkout/:socketIdx',
        element: <CheckoutPage />,
      },
      {
        path: 'stations/:deviceId/payment/:socketIdx',
        element: <StripeForm />,
      },
      {
        path: 'stations/:deviceId/status/:socketIdx',
        element: <StatusPage />,
      },
      {
        path: 'pinActivation/:uuid/:pin',
        element: <PinActivationPage />,
      },
      // { path: '/', element: <Navigate to='/app/search' /> },
      { path: '/', element: <Navigate to='/app/instructions' /> },
      { path: '*', element: <Navigate to='/404' /> },
    ],
  },
  {
    path: '/',
    element: <LandingPage />,
    children: [
      //   { path: 'login', element: <LoginView /> },
      //   { path: 'register', element: <RegisterView /> },
      //   { path: '404', element: <NotFoundView /> },
      //   { path: '/', element: <Navigate to="/app/dashboard" /> },
      { path: '*', element: <Navigate to='/404' /> },
    ],
  },
];

export default routes;

import { LoaderService } from '@hooks/loaderState';
import { StationsStore, useStationDetail } from '@hooks/stationsState';
import { ls } from '@i18n/translations';
import { Box, Typography, Button } from '@material-ui/core';
import { StationsService } from '@services/stationsService';
import React, { useState } from 'react';
import BarcodeScannerComponent from 'react-qr-barcode-scanner';
import { useLocation, useNavigate, useParams } from 'react-router';
import MessageBox from '@components/MessageBox';

var loadingStationDetail = false;

function InstructionsPage(
  props: React.PropsWithoutRef<{
    className?: string;
  }>
) {
  const [data, setData] = useState(ls.qrCodeScanSuggestion1);
  const [actionButton, setActionButton] = useState('');
  const [stopStream, setStopStream] = useState(false);
  const [noScannerAvailable, setNoScannerAvailable] = useState(false);

  const station = useStationDetail();
  const navigate = useNavigate();

  async function loadDetail(id: string) {
    if (loadingStationDetail) {
      return;
    }
    loadingStationDetail = true;
    LoaderService.startLoader();
    StationsService.getDetail(id, {})
      .then((station) => {
        console.log(station);
        StationsStore.updateStation(station);
        if (!!station && !!station.id) {
          setStopStream(true);
          setTimeout(() => {
            navigate(`/app/stations/${station.id}`);
            LoaderService.stopLoader();
            loadingStationDetail = false;
          }, 800);
        } else {
          setTimeout(() => {
            LoaderService.stopLoader();
            loadingStationDetail = false;
          }, 800);
        }
      })
      .catch(() => {
        setTimeout(() => {
          LoaderService.stopLoader();
          loadingStationDetail = false;
        }, 800);
      });
  }

  return (
    <Box
      display='flex'
      flexDirection='column'
      height='100%'
      flexGrow='1'
      flex='1'
      justifyContent='center'
      alignItems='center'
    >
      <Box mb={4}>
        <Typography
          align='center'
          variant='h3'
          gutterBottom
          style={{
            marginTop: 50,
            fontSize: '1.2rem',
          }}
        >
          {ls.qrCodeScan1}
        </Typography>
        <Typography
          variant='caption'
          style={{ textAlign: 'center' }}
          display='block'
          gutterBottom
        >
          {ls.qrCodeScan2}
        </Typography>
      </Box>
      {!noScannerAvailable && (
        <QRScanner
          onError={(error) => {
            console.log(error);
            setNoScannerAvailable(true);
          }}
          stopStream={stopStream}
          onUpdate={(err, result: any) => {
            // console.log('on update');
            if (
              result &&
              result.text &&
              result.text.split('/stations/').length === 2
            ) {
              console.log(result);
              let id = result.text.split('/stations/')[1];
              // setData(result.text.split('/stations/')[1]);
              loadDetail(id);

              // setActionButton('openDetail');
            } else setData(ls.qrCodeScanSuggestion1);
          }}
        ></QRScanner>
      )}
      {noScannerAvailable && (
        <MessageBox
          data={{
            severity: 'warning',
            message: ls.qrCodeScanNotAvailable,
          }}
        ></MessageBox>
      )}
      {/* <Typography
          align='center'
          variant='body1'
          gutterBottom
          style={{ marginTop: 50 }}
        >
          {
            
          }
        </Typography> */}
    </Box>
  );
}

function QRScanner(
  props: React.PropsWithoutRef<{
    className?: string;
    data?: any;
    station?: any;
    actionButton?: string;
    onUpdate: (err: any, result: any) => void;
    onError: (err: any) => void;
    stopStream: boolean;
  }>
) {
  const { data, station, actionButton, onUpdate, onError, stopStream } = props;

  return (
    <>
      <div className='qr-scanner-container'>
        <div className='qr-scanner-container-2'>
          <BarcodeScannerComponent
            width={466}
            height={466}
            onError={onError}
            stopStream={stopStream}
            onUpdate={onUpdate}
          />
          <div
            id='qr-square'
            style={{
              position: 'absolute',
              border: '1px solid green',
              width: '280px',
              height: '280px',
              left: 'calc(50% - 140px)',
              top: 'calc(50% - 126px)',
            }}
          ></div>
        </div>
      </div>
      <Box
        mt={3}
        mb={2}
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
      >
        {!station && (
          <Typography variant='body1' gutterBottom className='text-center'>
            {data}
          </Typography>
        )}
        {!!station && (
          <Typography variant='body1' gutterBottom className='text-center'>
            {station.name}
          </Typography>
        )}
      </Box>
      <Box mt={3}>
        {actionButton == 'openDetail' && (
          <Button
            variant='contained'
            size='large'
            color='primary'
            onClick={() => {
              // confirm();
            }}
            fullWidth
          >
            {'Conferma'}
          </Button>
        )}
      </Box>
    </>
  );
}

export default InstructionsPage;

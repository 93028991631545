export class En {
  welcomeMessage = "Welcome in";
  continue = "Continue";
  cancel = "Cancel";
  confirm = "Confirm";
  proceed = "Proceed";
  retry = "Retry";
  search = "Search";
  nothing = "Nothing";
  userHint1 = "Insert the pod code";
  insertPoDCode = "Pod Code";
  recharge = "Ricarica";
  meters = "meters";
  km = "km";
  currency = {
    eur: "€",
    usd: "$",
  };
  emailPlaceholder = "Your @email";
  box = "Box";
  locker = "Lock";
  boxSelectionTitle = "Box";
  boxSelectionHint = "Choose Box";
  toolsSelectionTitle = "Tools";
  toolsSelectionHint = "Choose Tools";
  sendToolsActivationCode = "Send code";
  lockerSelectionTitle = "Padlock of station";
  lockerSelectionHint = "Choose padlock";
  sendBoxPin = "Send box code";
  sendLockerPin = "Send padlock code";
  boxPinSentMessage = "You'll receive a mail with the code for opening the box";
  lockerPinSentMessage =
    "You'll receive a mail with the code for opening the padlock";
  socketPinSentMessage =
    "You'll receive a mail with the code for activationg charging";
  thankYouMessage1 = "Thank you, Good bye!";
  somethingWentWrong = "OOPS Something went wrong :(";
  genericError = "Error";
  operationSuccessToolsBox = "Operation complete";
  warningStationOffline = "WARNING: The station you have choose is OFFLINE";
  completeCheckoutWithPin = "Take your things";
  howToCompleteCheckout =
    "To collect your items, read the qrcode again and follow the wizard.";
  otherwise = "otherwise";
  goBack = "go back";
  chargingPoint = "Charging Point";
  chooseTheSocket = "Choose the socket";
  pinCodeHelperMessage = "Is this your pin?";
  insertYourPinCode = "Insert the PIN";
  yourPinCode = "Your PIN";
  insertYourEmail = "Insert Email";
  areYouThereOrHere = "You are not here?";
  emailNotValid = "Mail incorrect";
  confirmations = {
    conf1: "You've choose the charging station",
    conf2: "Confirm to continue",
    conf3: "You've choose the charging station",
    conf4: "and the socket",
  };
  insertRechargeTime = "Charging Time";
  minutes = "Minutes";
  congratulationsBoxOpened = "Congratulations! The box shuold be opened";
  congratulationsActivationCompleted = "Well done! Activation successful";
  ifBoxNotOpened =
    "The podlock or box is not open? Try again and pull the chain within 6 seconds";
  ifSocketNotActivated = "Socket not activated?Try again";
  howToCompleteCheckout2 =
    "Warning:Reaching the time the charging will be stopped";
  additionalInfo_title = "Additional station information";
  additionalInfo_ua = "UA";
  additionalInfo_prt = "PRT";
  choosePaymentOption = "Choose the amount";
  preconfiguredOption_1 = "1h = 4 €";
  preconfiguredOption_2 = "2h = 5.50 €";
  preconfiguredOption_3 = "4h = 8.50 €";
  preconfiguredOption_4 = "6h = 10.50 €";
  preconfiguredOption_5 = "10h = 13.50 €";
  preconfiguredOption_6 = "12h = 15.00 € ";
  preconfiguredOption_1_3 = "1h = 1.50 € ";
  preconfiguredOption_3_1 = "30 min = 1,00 €";
  preconfiguredOption_3_2 = "60 min = 1,50 €";
  preconfiguredOption_3_3 = "90 min = 2,00 €";
  preconfiguredOption_3_4 = "120 min = 2,50 €";
  preconfiguredOption_5_1 = "30 min = 0,50 €";
  preconfiguredOption_5_2 = "60 min = 1,00 €";
  preconfiguredOption_5_3 = "90 min = 1,50 €";
  preconfiguredOption_5_4 = "120 min = 2,00 €";
  paymentOkRechargeStarted =
    "Plug in the socket, your recharge will start soon.";
  paymentOkEmailPINSentLocker =
    "You'll receive an email with the PIN for opening the podlock";
  paymentOkEmailPINSentBox =
    "You'll receive an email with the PIN for opening the box";
  paymentOkEmailPINSentSocket =
    "You'll receive an email with the PIN for activating charge";
  doUWantARecharge = "Do you want to recharge?";
  station = "Station";
  qrCodeScan1 = "Find the QR Code";
  qrCodeScan2 = "Scan the QR code";
  qrCodeScanSuggestion1 = "Read a QR Code";
  qrCodeScanNotAvailable = "Scanner QR Code not available";
  cardOwner = "Name";
  email = "Email";
  pay = "Pay";
  processInProgress = "Processing...";
  serviceCheckedOut = "Taken";
  notAvailable = "Not available";
  openLocker = "Open Locker";
  insert4DigiCode = "Insert 4-digit code";
  invalidCode = "Invalid code";
}

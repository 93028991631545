import axios from 'axios';

const apiRoutes = {
  activate: (serviceId: number) => `/iot/services/${serviceId}/activate`,
  resetDeviceService: (serviceId: number) => `/iot/services/${serviceId}/reset`,
  stopDeviceService: (serviceId: number) => `/iot/services/${serviceId}/stop`,
  myWallets: () => `/users/myWallets`,
  submitUserPaymentIntent: '/transactions/submitUserPaymentIntent',
  cancelUserPaymentIntent: '/transactions/cancelUserPaymentIntent',
  getPendingPaymentInfo: (paymentIntentId: number) =>
    `/transactions/getPendingPaymentInfo/${paymentIntentId}`,
  getUserConsumption: (serviceId: number, userId: number) =>
    `/devices/services/${serviceId}/consumption/${userId}`,
  findNearbyDevices: () => `/devices/stationsNearby`,
  getDetail: (id: any) => `/devices/stations/${id}`,
  getPaymentInfo: (id: any) => `/transactions/getPaymentInfo/${id}`,
  sendServiceActivationPIN: (id: any) =>
    `/transactions/services/${id}/sendPinActivation`,
  activateWithPin: (uuid: any, pin: string) =>
    `/transactions/${uuid}/activatePin/${pin}`,
  completeCheckoutWithPin: (pin: string) =>
    `/transactions/completeCheckoutWithPin/${pin}`,
  openServiceBox: (stationId: any, deviceServiceId: string) =>
    `/iot/stations/${stationId}/openServiceBox/${deviceServiceId}`,
};

class ClientApi {
  submitUserPaymentIntent(data: {
    amount?: number;
    currency?: string;
    merchantId: number;
    podId: number;
    serviceId: number;
    time?: number;
    formData: any;
  }) {
    return axios
      .post(
        process.env.REACT_APP_API_URL + apiRoutes.submitUserPaymentIntent,
        data
      )
      .then((response) => response.data);
  }

  cancelUserPaymentIntent(paymentIntentId: string) {
    return axios.post(process.env.API_URL + apiRoutes.submitUserPaymentIntent, {
      paymentIntentId: paymentIntentId,
    });
  }

  findNearbyDevices(coords: { filter?: string; long?: number; lat?: number }) {
    return axios.get(
      process.env.REACT_APP_API_URL + apiRoutes.findNearbyDevices(),
      {
        params: {
          filter: coords.filter || '',
          long: coords.long || '',
          lat: coords.lat || '',
        },
      }
    );
  }

  getDetail(id: string, coords?: { long: number; lat: number }) {
    return axios
      .get(process.env.REACT_APP_API_URL + apiRoutes.getDetail(id), {
        params: {
          long: coords?.long,
          lat: coords?.lat,
        },
      })
      .then((response) => response.data);
  }

  getPaymentInfo(intentId: string) {
    return axios
      .get(process.env.REACT_APP_API_URL + apiRoutes.getPaymentInfo(intentId), {
        params: {},
      })
      .then((response) => response.data);
  }

  sendServiceActivationPIN(id: string, email: string, lang: string, data?: { time?: number}) {
    return axios.post(
      process.env.REACT_APP_API_URL + apiRoutes.sendServiceActivationPIN(id),
      { email, lang, data }
    );
  }

  async activateWithPin(uuid: string, pin: string) {
    try {
      let response = await axios.post(
        process.env.REACT_APP_API_URL + apiRoutes.activateWithPin(uuid, pin),
        {}
      );
      return response;
    } catch (err) {
      throw err;
    }

    // .catch((error) => {
    //     if (error.response) {
    //       console.log('AXIOS ERROR', error.response.data); // => the response payload
    //     }
    //   })
  }

  completeCheckoutWithPin(pin: string) {
    return axios.post(
      process.env.REACT_APP_API_URL + apiRoutes.completeCheckoutWithPin(pin),
      {}
    );
  }

  openServiceBox(stationId: string, deviceServiceId: string) {
    return axios.post(
      process.env.REACT_APP_API_URL +
        apiRoutes.openServiceBox(stationId, deviceServiceId),
      {}
    );
  }
}

export const ClientService = new ClientApi();

import { ls } from "@i18n/translations";
import { Box, Button, Typography } from "@material-ui/core";
import React from "react";

function MessageBox(
  props: React.PropsWithoutRef<{
    className?: string;
    data?: { severity?: string; message: any };
    action?: () => void | undefined;
    back?: () => void;
  }>
) {
  const { data, action, back } = props;

  let message = data?.message;
  let severity = data?.severity;

  const infoBoxClass = `infoBox ${!!severity ? severity : ""}`;

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      flexGrow="1"
      flex="1"
      justifyContent="center"
      alignItems="center"
    >
      <div className="infoBoxContainer">
        <Box
          className={infoBoxClass}
          display="flex"
          flexDirection="column"
          flexGrow="1"
          flex="1"
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="caption" display="block" gutterBottom>
            {message}
          </Typography>
        </Box>
      </div>

      {action !== undefined && (
        <>
          <Button
            className="huge"
            size="large"
            color="primary"
            onClick={() => {
              action();
            }}
          >
            {ls.continue}
          </Button>
          <Typography variant="caption" display="block" gutterBottom>
            {ls.otherwise}
          </Typography>
        </>
      )}
      {!!back && (
        <Button
          onClick={() => {
            back();
          }}
        >
          <Typography variant="button" display="block" gutterBottom>
            {ls.goBack}
          </Typography>
        </Button>
      )}
    </Box>
  );
}

export default MessageBox;

import React, { Component } from "react";
import { Helmet } from "react-helmet";

function Page(
  props: React.PropsWithChildren<{
    className?: string;
    title?: string;
    children?: any;
  }>
) {
  return (
    <div style={{ height: "100%" }}>
      <Helmet>
        <title>{props.title}</title>
      </Helmet>
      {props.children}
    </div>
  );
}

export default Page;

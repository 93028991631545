import Page from '@components/Page';
import Timedots from '@components/Timedots';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { StationsStore, useStationDetail } from '@hooks/stationsState';
import { currentLang, ls } from '@i18n/translations';
import {
  Box,
  Button,
  FormHelperText,
  FormLabel,
  Input,
  makeStyles,
  Snackbar,
  Typography,
} from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { ClientService } from '@services/clientApi';
import { StationsService } from '@services/stationsService';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { ValidateEmail } from 'src/utils';
import { LoaderService } from '@hooks/loaderState';
import MessageBox from '@components/MessageBox';
import PaymentFlow from '@components/PaymentFlow';

const useStyles = makeStyles((theme) => ({
  page: {
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

async function loadDetail(id: string) {
  LoaderService.startLoader();
  // let lastLocation = getLastLocation();
  // let query = null;
  // if (lastLocation && lastLocation.length) {
  //   query = { lat: lastLocation[0], long: lastLocation[1] };
  // }

  await StationsService.getDetail(id, {})
    .then((station) => {
      // console.log('POD DATA...', station);
      StationsStore.updateStation(station);
      unitCost = parseFloat(station.unitCost);
      merchantId = station.merchantId;
      setTimeout(() => {
        LoaderService.stopLoader();
      }, 800);
    })
    .catch(() => {
      setTimeout(() => {
        LoaderService.stopLoader();
      }, 800);
    });
}

var formData = {} as any;
var unitCost = 0;
var merchantId = 0;

const CheckoutPage = () => {
  let { deviceId, socketIdx } = useParams();
  const classes = useStyles();
  const navigate = useNavigate();
  // const [show, set] = useState(false);
  // const [error, setError] = useState<any>(null);
  const [showSnack, setShowSnack] = useState({
    open: false,
  } as any);
  const [showMessageBox, setMessageBox] = useState(null as any);
  // const [amount, setAmount] = useState(0);
  // const [time, setTime] = useState({
  //   hours: 0,
  //   minutes: 30,
  // });
  const [loaded, setLoaded] = useState(false);
  const [email, setEmail] = useState('');
  let emailError = '';
  if (email && !ValidateEmail(email)) {
    emailError = ls.emailNotValid;
  }

  const location = useLocation();

  useEffect(() => {

    loadDetail(deviceId).finally(() => {
      setLoaded(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const station = useStationDetail();

  const stationService = station?.sockets?.find(
    (s: any) => s.id == parseInt(socketIdx)
  );

  const [_formData, set_formData] = useState(formData);

  function updateFormData(data: any) {
    formData = { ...formData, ...data };
    // console.log("FORM DATA", formData);
    set_formData(formData);
  }

  let hasPayment = !!station?.config?.paymentRequired;
  let hasAdditionalInfo = !!station?.config?.paymentFlow?.data?.additionalInfo;

  let isAdditionalInfoFormValid = hasAdditionalInfo
    ? _formData?.additionalInfo &&
    !Object.keys(_formData?.additionalInfo).some((key) => {
      return (
        _formData?.additionalInfo[key].required &&
        !_formData?.additionalInfo[key].value
      );
    })
    : true;

  async function continueCheckout() {
    try {
      // console.log('continue checkout', formData);
      formData.lang = currentLang;

      let res = await ClientService.submitUserPaymentIntent({
        // amount: formData.amount,
        currency: 'eur',
        merchantId,
        podId: parseInt(station.id),
        serviceId: parseInt(socketIdx),
        formData,
        // time: getTimeToSeconds(formData.time),
      });

      sessionStorage.setItem('checkoutData', JSON.stringify(res));
      // salvo l'intent
      // console.log(res);

      navigate(`/app/stations/${station.id}/payment/${socketIdx}`);
    } catch (err) {
      // Mostrare errore
      // setError({
      //   error: 1,
      //   message: ls.genericError,
      // });
      setShowSnack({
        open: true,
        severity: 'error',
        message: ls.genericError,
      });
    }
  }

  async function confirmCheckout() {
    try {
      console.log(stationService.id, formData?.email, currentLang);
      await StationsService.sendServiceActivationPIN(
        stationService.id,
        formData.email,
        currentLang,
        {
          time: formData?.time && getTimeToSeconds(formData?.time) || 0,
        }
      );

      setMessageBox({
        message: ls.socketPinSentMessage,
      });
    } catch (err) {
      setShowSnack({
        open: true,
        severity: 'error',
        message: ls.genericError,
      });
    }
  }

  function getTimeToSeconds(time: { hours: number; minutes: number }) {
    return time.hours * 3600 + time.minutes * 60;
  }

  let messageBox = !!showMessageBox && (
    <MessageBox
      data={showMessageBox as any}
      action={() => {
        navigate(`/app/stations/${deviceId}`);
      }}
      back={() => {
        setMessageBox(null);
      }}
    ></MessageBox>
  );

  let detail = (
    <Box
      display='flex'
      flexDirection='column'
      height='100%'
      flexGrow='1'
      flex='1'
      justifyContent='center'
      alignItems='center'
      style={{ marginBottom: 90 }}
    >
      <PaymentFlow
        station={station}
        formData={_formData}
        updateFormData={(data: any) => {
          // console.log("Updating form data..")
          updateFormData(data);
        }}
      ></PaymentFlow>

      {!hasPayment && (
        <Box mt={3} mb={5} display='flex' flexDirection='column'>
          <FormLabel style={{ marginBottom: 8 }} component='legend'>
            {ls.insertYourEmail}
          </FormLabel>
          <Input
            error={!!emailError}
            className='slim'
            value={email}
            fullWidth
            type={'email'}
            placeholder={ls.emailPlaceholder}
            required={true}
            onChange={(event: any) => {
              setEmail(event?.target?.value);
              updateFormData({ email: event?.target?.value });
            }}
          ></Input>
          <FormHelperText>{emailError}</FormHelperText>
        </Box>
      )}

      <Box>
        <Button
          className='huge'
          size='large'
          disabled={
            (!hasPayment && (!!emailError || !email)) ||
            !isAdditionalInfoFormValid
          }
          color='primary'
          onClick={() => {
            if (!hasPayment) {
              confirmCheckout();
            } else {
              continueCheckout();
            }
          }}
          fullWidth
        >
          {ls.continue}
        </Button>
      </Box>

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={showSnack.open}
        onClose={() => {
          setShowSnack({
            open: false,
          });
        }}
        autoHideDuration={15000}
      >
        <MuiAlert
          elevation={6}
          variant='filled'
          onClose={() => {
            setShowSnack({ open: false });
          }}
          severity={showSnack.severity}
        >
          {showSnack.message}
        </MuiAlert>
      </Snackbar>
    </Box>
  );

  return (
    <Page className={classes.page} title='Search'>
      {!loaded ? <></> : (showMessageBox ? messageBox : detail)}
    </Page>
  );
};

export default CheckoutPage;

import React, { Component } from 'react';
import logo from './logo.svg';

const SvgIcon = () => (
  <svg
    width='12'
    height='25'
    viewBox='0 0 12 25'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g filter='url(#filter0_d)'>
      <path
        d='M6.00506 7.364C7.94906 7.364 9.49706 5.888 9.49706 3.836C9.49706 1.748 7.94906 0.379999 6.00506 0.379999C4.06106 0.379999 2.51306 1.748 2.51306 3.836C2.51306 5.888 4.06106 7.364 6.00506 7.364ZM6.00506 20.288C7.94906 20.288 9.49706 18.812 9.49706 16.76C9.49706 14.672 7.94906 13.304 6.00506 13.304C4.06106 13.304 2.51306 14.672 2.51306 16.76C2.51306 18.812 4.06106 20.288 6.00506 20.288Z'
        fill='#56C26D'
      />
    </g>
    <defs>
      <filter
        id='filter0_d'
        x='0.513184'
        y='0.379883'
        width='10.984'
        height='23.908'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
        />
        <feOffset dy='2' />
        <feGaussianBlur stdDeviation='1' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'
        />
        <feBlend
          mode='normal'
          in2='BackgroundImageFix'
          result='effect1_dropShadow'
        />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='effect1_dropShadow'
          result='shape'
        />
      </filter>
    </defs>
  </svg>
);

function Timedots() {
  return <SvgIcon></SvgIcon>;
}

export default Timedots;

import { ls } from '@i18n/translations';
import { Box, Button, FormLabel, Icon, Typography } from '@material-ui/core';
import React, { Component, useState } from 'react';
import { ValidateEmail } from 'src/utils';

function ServiceBox(
  props: React.PropsWithoutRef<{
    className?: string;
    data?: {
      icon: string;
      title: string;
      message?: any;
      iconColor?: string;
      textColor?: string;
      boxBackground?: string;
    };
    action: () => void;
    back: () => void;
  }>
) {
  const { data, action, back } = props;

  let message = data?.message;
  let title = data?.title;
  let icon = data?.icon;
  let iconColor = data?.iconColor;
  let textColor = data?.textColor;
  let boxBackground = data?.boxBackground;

  return (
    <Box
      display='flex'
      flexDirection='column'
      height='100%'
      flexGrow='1'
      flex='1'
      justifyContent='center'
      alignItems='center'
    >
      <div
        className='serviceBoxContainer'
        onClick={() => {
          action();
        }}
      >
        <Box
          className='serviceBox'
          display='flex'
          style={{
            background: boxBackground,
          }}
          flexDirection='column'
          flexGrow='1'
          flex='1'
          justifyContent='center'
          alignItems='center'
        >
          <Icon style={{ color: iconColor || 'white', fontSize: 50 }}>
            {icon}
          </Icon>
          <Typography
            style={{ color: textColor, fontWeight: 700, marginTop: 2 }}
            variant='body1'
            display='block'
          >
            {title}
          </Typography>
        </Box>
      </div>
    </Box>
  );
}

export default ServiceBox;

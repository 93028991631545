import { ls } from "@i18n/translations";
import {
  Box,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import AdditionalInfo from "./AdditionalInfo";
import Timedots from "./Timedots";
const CurrencyFormat = require("react-currency-format");

function calculateAmount(unitCost: number, time: any) {
  return (unitCost / 60) * time.minutes + unitCost * time.hours;
}

var hasPayment = false;

function PaymentFlow(
  props: React.PropsWithoutRef<{
    className?: string;
    station: any;
    formData: any;
    updateFormData: (data: any) => void;
  }>
) {
  const { station, updateFormData, formData } = props;
  hasPayment = !!station?.config?.paymentRequired;
  let paymentFlow = station?.config?.paymentFlow;

  // costruisco il form chiave valore
  let additionalInfoForm = !!paymentFlow?.data?.additionalInfo
    ? (paymentFlow?.data?.additionalInfo || []).reduce(
        (red: any, curr: any) => {
          red[curr.key] = curr;
          return red;
        },
        {}
      )
    : null;

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      {
        // raccolgo le infomazioni aggiuntive se sto cercando di riservere un servizio
        !!additionalInfoForm && (
          <Box
            display="flex"
            flexDirection="column"
            style={{ marginBottom: 20 }}
          >
            <Typography align="center" variant="body1" gutterBottom>
              {ls.additionalInfo_title}
            </Typography>
            {AdditionalInfo({
              formData: formData,
              additionalInfo: additionalInfoForm,
              updateFormData: (updated: any) => {
                console.log("updated form data...");

                updateFormData({
                  additionalInfo: {
                    ...(formData?.additionalInfo || {}),
                    ...updated,
                  },
                });
              },
            })}
          </Box>
        )
      }

      <Box mb={3}>
        {!paymentFlow ||
          (paymentFlow?.type == "timerFlow" && (
            <TimerFlow {...props}></TimerFlow>
          ))}
        {paymentFlow?.type == "preconfiguredOption" && (
          <PreconfiguredOptionFlow {...props}></PreconfiguredOptionFlow>
        )}
      </Box>
    </Box>
  );
}

function TimerFlow(
  props: React.PropsWithoutRef<{
    className?: string;
    station: any;
    formData: any;
    updateFormData: (data: any) => void;
  }>
) {
  const { station, updateFormData } = props;
  let unitCost = parseFloat(station.unitCost);

  const [time, setTime] = useState({
    hours: 0,
    minutes: 30,
  });
  let _amount = calculateAmount(unitCost, time);
  // updateFormData({ amount: _amount });
  const [amount, setAmount] = useState(_amount);
  setTimeout(() => {
    updateFormData({ amount: _amount, time });
  }, 500);

  function handleHoursChange(event: any) {
    let newValue =
      parseInt(event.target.value) > 0 ? parseInt(event.target.value) : 0;
    let updatedTime = {
      ...time,
      hours: newValue,
    };
    setTime(updatedTime);

    // console.log(time);

    let _amount = calculateAmount(unitCost, updatedTime);
    setAmount(_amount);
    updateFormData({ amount: _amount, time: updatedTime });
  }

  function handleMinutesChange(event: any) {
    let newValue =
      parseInt(event.target.value) > 0 ? parseInt(event.target.value) : 0;
    let updatedTime = {
      ...time,
      minutes: newValue,
    };
    setTime(updatedTime);

    let _amount = calculateAmount(unitCost, updatedTime);
    setAmount(_amount);
    updateFormData({ amount: _amount, time: updatedTime });
  }

  return (
    <Box
      mt={3}
      mb={2}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Typography variant="body2" gutterBottom style={{ marginBottom: "1rem" }}>
        {ls.minutes}
      </Typography>
      <Typography
        variant="caption"
        color="secondary"
        display="block"
        gutterBottom
        style={{ marginBottom: "1rem" }}
      >
        {ls.insertRechargeTime}
      </Typography>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        style={{ marginBottom: "10vh" }}
      >
        <Input
          className="time-input"
          value={time.hours}
          onChange={(event) => {
            handleHoursChange(event);
          }}
        ></Input>
        <div
          style={{ marginTop: "5px", marginLeft: "8px", marginRight: "8px" }}
        >
          <Timedots></Timedots>
        </div>
        <Input
          className="time-input"
          value={time.minutes}
          onChange={(event) => {
            handleMinutesChange(event);
          }}
        ></Input>
      </Box>

      {hasPayment && (
        <Typography variant="h5" style={{ marginBottom: "10vh" }}>
          <CurrencyFormat
            value={amount}
            fixedDecimalScale={true}
            decimalScale={2}
            suffix={ls.currency["eur"]}
            displayType="text"
          ></CurrencyFormat>
        </Typography>
      )}
    </Box>
  );
}

function PreconfiguredOptionFlow(
  props: React.PropsWithoutRef<{
    className?: string;
    station: any;
    formData: any;
    updateFormData: (data: any) => void;
  }>
) {
  const { station, updateFormData, formData } = props;
  let paymentFlow = station?.config?.paymentFlow as any;

  let options = paymentFlow?.data?.preconfiguredOptions;
  let langs = ls as any;

  let sortedOpt = options?.sort((a: any, b: any) => {
    return a.id < b.id ? -1 : a.id > b.id ? 1 : 0;
  });

  let _selected = formData?.preconfiguredOption;
  if (_selected === undefined || !_selected) {
    _selected = sortedOpt[0].id;
    setTimeout(() => {
      updateFormData({
        preconfiguredOption: _selected,
        paymentFlow: paymentFlow.id,
      });
    }, 100);
  }
  return (
    <Box
      mt={3}
      mb={2}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <FormControl style={{ minWidth: 184 }}>
        <InputLabel shrink>{ls.choosePaymentOption}</InputLabel>
        <Select
          id="service-select"
          value={_selected}
          onChange={(event) => {
            let val = event.target.value;
            updateFormData({
              preconfiguredOption: val,
              paymentFlow: paymentFlow.id,
            });
          }}
          style={{ marginTop: 20 }}
          displayEmpty
        >
          <MenuItem key="opt-none" value="">
            <em>{ls.nothing}</em>
          </MenuItem>
          {sortedOpt.map((opt: any, idx: number) => {
            return (
              <MenuItem key={"opt-" + idx} value={opt.id}>
                {langs[`${opt.label}`]}
              </MenuItem>
            );
          })}
        </Select>
        {/* <FormHelperText>{hintText}</FormHelperText> */}
      </FormControl>
    </Box>
  );
}

export default PaymentFlow;

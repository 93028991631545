import Logo from "@assets/images/logo.svg";
// import Logo from "@components/Logo";
import {
  AppBar,
  createStyles,
  IconButton,
  isWidthUp,
  makeStyles,
  Toolbar,
} from "@material-ui/core";
import { Menu } from "@material-ui/icons";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { currentLang, ls } from "@i18n/translations";
import ReactFlagsSelect from "react-flags-select";
import { useStationDetail } from "@hooks/stationsState";
import { AppContext } from "src/state/AppState";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.default,
      marginBottom: 290,
    },
    toolbar: {
      minHeight: 90,
      backgroundColor: theme.palette.background.default,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
  })
);

const TopBar = (
  props: React.PropsWithoutRef<{
    className?: string;
    title?: string;
    onMobileNavOpen?: () => void;
  }>
) => {
  const { onMobileNavOpen, className, ...rest } = props;
  const classes = useStyles();

  // console.log(
  //   'PATH',
  //   window.location.pathname,
  //   window.location.pathname.split('/').length
  // );
  // window.location.pathname.split('/').length <= 4;

  const [state, dispatch] = React.useContext(AppContext) as any;
  let stationLogoUrl = state?.stationDetail?.config?.stationLogoUrl;

  return (
    <AppBar
      position="fixed"
      className={clsx(classes.root, className)}
      elevation={0}
      {...rest}
    >
      <Toolbar className={classes.toolbar} style={{ minHeight: !!stationLogoUrl ? 22 : 90}}>
        {/* <IconButton
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
          onClick={() => {
            console.log("menu clicked");
            if (onMobileNavOpen) {
              onMobileNavOpen();
            }
          }}
        >
          <Menu fontSize="large" color="primary"></Menu>
        </IconButton> */}
        {!stationLogoUrl && (
          <div
            style={{
              position: "fixed",
              top: "50px",
              right: "50%",
              transform: "translate(50%, -50%)",
            }}
          >
            <Logo
              style={{
                transform: "scale(0.6)",
              }}
            ></Logo>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};

export default TopBar;

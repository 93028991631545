import { currentLang, setAppLang } from "@i18n/translations";
import { makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import ReactFlagsSelect from "react-flags-select";
import { Outlet } from "react-router-dom";
import { AppContext } from "src/state/AppState";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: "flex",
    flexDirection: "column",
    flex: 1,
    flexGrow: 1,
    minHeight: "100vh",
    overflow: "auto",
  },
  wrapper: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "auto",
    flexGrow: 1,
    height: "100%",
    paddingTop: 90,
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    flex: "1 1 auto",
  },
  content: {
    flex: "1 1 auto",
    paddingRight: "18px",
    paddingLeft: "18px",
    overflow: "auto",
  },
}));

const langToCode = {
  it: "IT",
  en: "GB",
  de: "DE",
} as any;

const Content = () => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const enableLanguageSelection = true;

  const [selected, setSelected] = useState(
    langToCode[currentLang as string] as string
  );
  const [state, dispatch] = React.useContext(AppContext) as any;
  let stationLogoUrl = state?.stationDetail?.config?.stationLogoUrl;

  return (
    <div
      className={classes.wrapper}
      style={{ paddingTop: !!stationLogoUrl ? 22 : 90 }}
    >
      <div className={classes.contentContainer}>
        {true && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 15,
              marginBottom: 15,
            }}
          >
            <ReactFlagsSelect
              className="custom-flags"
              countries={["GB", "DE", "IT"]}
              disabled={!enableLanguageSelection}
              customLabels={{
                GB: "EN",
                DE: "DE",
                IT: "IT",
              }}
              placeholder="Select Language"
              selected={selected}
              onSelect={(code: string) => {
                let _code = code.toLowerCase();
                if (code === "US" || code === "GB") {
                  _code = "en";
                }
                console.log(code, _code);
                setAppLang(_code);
                setSelected(code);
                localStorage.setItem("selectedLang", _code);
                window.location.reload(false);
              }}
            />
          </div>
        )}
        {!!stationLogoUrl && (
          <div
            style={{
                alignSelf: "center",
                marginTop: 20,
            }}
          >
            <img
              src={stationLogoUrl}
              style={{
                width: 130,
                height: 130,
                marginBottom: 10,
                borderRadius: "50%",
              }}
            />
          </div>
        )}
        <div className={classes.content}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Content;

import { makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { AppStateProvider } from "src/state/AppState";
import Content from "./Content";
import NavBar from "./NavBar/NavBar";
import TopBar from "./TopBar";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: "flex",
    flexDirection: "column",
    flex: 1,
    flexGrow: 1,
    minHeight: "100vh",
    overflow: "auto",
  },
  // wrapper: {
  //   display: "flex",
  //   flex: "1 1 auto",
  //   overflow: "auto",
  //   flexGrow: 1,
  //   height: "100%",
  //   paddingTop: 90,
  // },
  // contentContainer: {
  //   display: "flex",
  //   flexDirection: "column",
  //   overflow: "auto",
  //   flex: "1 1 auto",
  // },
  // content: {
  //   flex: "1 1 auto",
  //   paddingRight: "18px",
  //   paddingLeft: "18px",
  //   overflow: "auto",
  // },
}));

const MainLayout = () => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const enableLanguageSelection = true;

  return (
    <AppStateProvider>
      <div className={classes.root}>
        <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
        <NavBar
          onMobileClose={() => setMobileNavOpen(false)}
          openMobile={isMobileNavOpen}
        />
        <Content></Content>
      </div>
    </AppStateProvider>
  );
};

export default MainLayout;

import MessageBox from "@components/MessageBox";
import Page from "@components/Page";
import SimpleChargingStation from "@components/SimpleChargingStation";
import StationWithLockers from "@components/StationWithLockers";
import { LoaderService } from "@hooks/loaderState";
import { StationsStore, useStationDetail } from "@hooks/stationsState";
import { currentLang, ls } from "@i18n/translations";
import {
  Box,
  Button,
  makeStyles,
  Snackbar,
  Typography,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { ClientService } from "@services/clientApi";
import { StationsService } from "@services/stationsService";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { checkStationOnline, getSocketShortName } from "src/utils";

const useStyles = makeStyles((theme) => ({
  page: {
    height: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

function getLastLocation() {
  let _json = localStorage.getItem("stationsFrom");
  let lastLocation = _json && JSON.parse(_json);
  if (!lastLocation) {
    lastLocation = [45.72807936694571, 11.78751416932071];
  }
  return lastLocation;
}

function loadDetail(id: string) {
  LoaderService.startLoader();
  let lastLocation = getLastLocation();
  let query = null;
  if (lastLocation && lastLocation.length) {
    query = { lat: lastLocation[0], long: lastLocation[1] };
  }

  return StationsService.getDetail(id, query).then((station) => {
    StationsStore.updateStation(station);
  });
}

var formData = {} as any;

const StationDetailPage = () => {
  let { deviceId } = useParams();

  const location = useLocation();

  if (
    window.location.hostname.includes("cloud.ricariche-bike") &&
    typeof deviceId == "string" &&
    deviceId.startsWith("demo")
  ) {
    window.location.assign(
      `https://dhkp22dqjsw7n.cloudfront.net/app/stations/${deviceId}`
    );
    // return;
  }

  useEffect(() => {
    loadDetail(deviceId)
      .then(() => {
        setTimeout(() => {
          setLoading(false);
          LoaderService.stopLoader();
        }, 2800);
      })
      .catch(() => {
        setTimeout(() => {
          setLoading(false);
          LoaderService.stopLoader();
        }, 2800);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const station = useStationDetail();

  const navigate = useNavigate();
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [showConfirmation, setConfirmation] = useState(false);
  const [showMessageBox, setMessageBox] = useState(null as any);
  const [showSnack, setShowSnack] = useState({ open: false } as any);
  const [stationService, setStationService] = useState<any>(null);

  function handleSocketSelect(selected: any) {
    setStationService(selected);
  }

  function confirmSelection() {
    // if(station?.type == "STATION_WITH_LOCKERS")
    setConfirmation(true);
  }

  function confirmCheckout() {
    // console.log('confirm of socket', socket);
    let hasPayment = !!station?.config.paymentRequired;

    if (
      (station?.type === "STATION_WITH_LOCKERS" ||
        station?.type === "ONLY_LOCKERS" ||
        station?.type === "ONLY_LOCKERS_NOPIN" ||
        station?.type === "SOCKETS_AND_LOCKERS" ||
        station?.type === "SINGLE_BOX_STATION") &&
      !!stationService
    ) {
      // let selectedBox = station.boxes.find(
      //   (b: any) => b.id === parseInt(socket)
      // );
      if (stationService && !!stationService.checkout) {
        ClientService.completeCheckoutWithPin(formData.checkoutPin).then(
          (response) => {
            localStorage.removeItem("checkoutPIN");
            localStorage.removeItem("checkoutID");
            setConfirmation(false);
            setMessageBox({
              message: ls.thankYouMessage1,
            });
          }
        );
      } else if (!!hasPayment) {
        navigate(`/app/stations/${deviceId}/checkout/${stationService.id}`);
      } else {
        const is_bugg =
          station?.id === 64 && station?.type === "ONLY_LOCKERS_NOPIN";
        StationsService.sendServiceActivationPIN(
          stationService.id,
          is_bugg ? "0123" : formData.email,
          currentLang /* ,
          {
            time: getTimeToSeconds(formData?.time) || 0,
          } */
        )
          .then((response) => {
            setConfirmation(false);
            setMessageBox({
              message:
                station?.type === "ONLY_LOCKERS_NOPIN"
                  ? ls.congratulationsActivationCompleted
                  : station?.type === "ONLY_LOCKERS" ||
                    station?.type === "SOCKETS_AND_LOCKERS"
                  ? ls.lockerPinSentMessage
                  : ls.boxPinSentMessage,

              back:
                station?.type !== "ONLY_LOCKERS_NOPIN"
                  ? undefined
                  : () => {
                      navigate(`/app/stations/${station?.slug}`);
                    },
            });
          })
          .catch((error) => {
            // loaded = true;
            // console.log(error?.response?.data?.data?.deviceService);
            // setStationService(error?.response?.data?.data?.deviceService);
            // setShowResult(true);
            // setResult({
            //   message: ls.somethingWentWrong,
            // });
            setShowSnack({
              open: true,
              severity: "error",
              message: ls.genericError,
            });
          });
      }
    } else {
      navigate(`/app/stations/${deviceId}/checkout/${stationService.id}`);
    }
  }

  function getTimeToSeconds(time: { hours: number; minutes: number }) {
    return time.hours * 3600 + time.minutes * 60;
  }

  function openServiceBox(tool: any) {
    if (station?.type == "STATION_WITH_LOCKERS") {
      ClientService.openServiceBox(deviceId, tool.id)
        .then((response) => {
          setShowSnack({
            open: true,
            severity: "success",
            message: ls.operationSuccessToolsBox,
          });
        })
        .catch(() => {
          setShowSnack({
            open: true,
            severity: "error",
            message: ls.somethingWentWrong,
          });
        });
    }
  }

  let detail = station && (
    <StationServiceDetail
      data={{
        station,
        stationService,
      }}
      updateFormData={(data) => {
        formData = { ...formData, ...data };
      }}
      goBack={() => {
        navigate(`/app/stations/${deviceId}`);
      }}
      select={(event) => handleSocketSelect(event)}
      confirm={() => confirmSelection()}
      openServiceBox={(tool: any) => openServiceBox(tool)}
    ></StationServiceDetail>
  );

  let confirmation = station && (
    <Confirmation
      data={{
        station,
        stationService,
      }}
      confirm={() => {
        confirmCheckout();
      }}
      back={() => {
        setConfirmation(false);
      }}
    ></Confirmation>
  );

  let messageBox = !!showMessageBox && (
    <MessageBox
      data={showMessageBox as any}
      // action={() => {
      //   navigate(`/`);
      // }}
      back={() => {
        setMessageBox(null);
      }}
    ></MessageBox>
  );

  let isStationOnline = checkStationOnline(station);

  if (!loading && isStationOnline) {
    return (
      <Page className={classes.page} title="Search">
        {showConfirmation ? confirmation : showMessageBox ? messageBox : detail}
        <Snackbar
          open={showSnack.open}
          onClose={() => {
            setShowSnack({
              open: false,
            });
          }}
          autoHideDuration={5000}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={() => {
              setShowSnack({ open: false });
            }}
            severity={showSnack.severity}
          >
            {showSnack.message}
          </MuiAlert>
        </Snackbar>
      </Page>
    );
  } else if (!loading) {
    return (
      <Page className={classes.page} title="Search">
        <MessageBox
          data={{
            message: ls.warningStationOffline,
          }}
          back={() => {
            navigate(`/app/stations/${deviceId}`);
            setMessageBox(null);
          }}
        ></MessageBox>
      </Page>
    );
  } else {
    return <></>;
  }
};

function Confirmation(
  props: React.PropsWithoutRef<{
    className?: string;
    data?: { station: any; stationService: any };
    confirm: () => void;
    back: () => void;
  }>
) {
  const { data, confirm, back } = props;

  let station = data?.station;

  let stationService = data?.stationService;

  // console.log('STATION SERIVCE', stationService);

  let showGenericStationMessage =
    station.type === "STATION_WITH_LOCKERS" ||
    station.type === "SOCKETS_AND_LOCKERS" ||
    station.type === "ONLY_LOCKERS" ||
    station.type === "ONLY_LOCKERS_NOPIN" ||
    station.type === "SINGLE_BOX_STATION";

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      flexGrow="1"
      flex="1"
      justifyContent="center"
      alignItems="center"
    >
      <div className="infoBoxContainer">
        <Box
          className="infoBox"
          display="flex"
          flexDirection="column"
          flexGrow="1"
          flex="1"
          justifyContent="center"
          alignItems="center"
        >
          {showGenericStationMessage && (
            <Typography variant="caption" display="block" gutterBottom>
              {`${ls.confirmations.conf1} ${station.name}. ${ls.confirmations.conf2}`}
            </Typography>
          )}
          {station.type === "BASIC" && (
            <Typography variant="caption" display="block" gutterBottom>
              {`${ls.confirmations.conf3} ${station.name} ${
                ls.confirmations.conf4
              } ${getSocketShortName(stationService.serviceIdx)}. ${
                ls.confirmations.conf2
              }`}
            </Typography>
          )}
        </Box>
      </div>

      <Button
        className="huge"
        size="large"
        color="primary"
        onClick={() => {
          confirm();
        }}
      >
        {ls.confirm}
      </Button>
      <Typography variant="caption" display="block" gutterBottom>
        {ls.otherwise}
      </Typography>
      <Button
        onClick={() => {
          back();
        }}
      >
        <Typography variant="button" display="block" gutterBottom>
          {ls.goBack}
        </Typography>
      </Button>
    </Box>
  );
}

function StationServiceDetail(
  props: React.PropsWithoutRef<{
    className?: string;
    data?: { station: any; stationService: any };
    select: (event: any) => void;
    goBack: () => void;
    updateFormData: (data: any) => void;
    confirm: () => void;
    openServiceBox?: (tool: any) => void;
  }>
) {
  const { data, select, confirm, goBack, updateFormData, openServiceBox } =
    props;
  let station = data?.station;
  // let stationService = data?.stationService;
  let type = station.type;

  switch (type) {
    case "SINGLE_BOX_STATION":
    case "SOCKETS_AND_LOCKERS":
    case "ONLY_LOCKERS":
    case "ONLY_LOCKERS_NOPIN":
    case "STATION_WITH_LOCKERS": {
      return (
        <StationWithLockers
          confirm={confirm}
          select={select}
          goBack={goBack}
          updateFormData={updateFormData}
          data={data}
          openServiceBox={openServiceBox}
        ></StationWithLockers>
      );
    }
    default: {
      return (
        <SimpleChargingStation
          confirm={confirm}
          select={select}
          goBack={goBack}
          data={data}
        ></SimpleChargingStation>
      );
    }
  }
}

export default StationDetailPage;

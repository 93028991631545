import { ls } from "@i18n/translations";
import {
  Box,
  Button,
  FormHelperText,
  FormLabel,
  Icon,
  Input,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { Validate4digitCode, ValidateEmail } from "src/utils";
import AdditionalInfo from "./AdditionalInfo";
import ServiceBox from "./ServiceBox";
import ServiceSelection from "./ServiceSelection";

function StationWithLockers(
  props: React.PropsWithoutRef<{
    className?: string;
    data?: { station: any; stationService: any };
    select: (event: any) => void;
    confirm: () => void;
    goBack: () => void;
    openServiceBox?: (tool: any) => void;
    updateFormData: (data: any) => void;
  }>
) {
  const { data, select, confirm, updateFormData, goBack, openServiceBox } =
    props;
  let station = data?.station;
  let stationType = station.type;
  let hasToolsBox = !!data?.station?.tools?.length;
  let hasPayment = !!station?.config.paymentRequired;
  let orderServiceBy = station?.config?.orderBy;
  // let stationLogoUrl = data?.station?.config?.stationLogoUrl;

  // costruisco il form chiave valore
  let additionalInfoForm =
    (!hasPayment &&
      !!station?.config?.paymentFlow &&
      (station?.config.paymentFlow?.data?.additionalInfo || []).reduce(
        (red: any, curr: any) => {
          red[curr.key] = curr;
          return red;
        },
        {}
      )) ||
    null;

  const [additionalInfo, setAdditionalInfo] = useState(additionalInfoForm);

  let withBoxSelection =
    stationType === "STATION_WITH_LOCKERS" ||
    stationType === "SINGLE_BOX_STATION";

  const noEmail = stationType === "ONLY_LOCKERS_NOPIN";

  const is_bugg = station?.id === 64 && stationType === "ONLY_LOCKERS_NOPIN";

  let stationService = data?.stationService;
  // console.log('STATION SERVICE', stationService);

  const [email, setEmail] = useState("");
  let emailError = "";
  if (!noEmail && email && !ValidateEmail(email)) {
    emailError = ls.emailNotValid;
  }
  if (!!noEmail && email && !Validate4digitCode(email)) {
    emailError = ls.invalidCode;
  }
  let lastCheckoutPin = localStorage.getItem("checkoutPIN") || "";
  const [checkoutPin, setCheckoutPin] = useState(lastCheckoutPin);
  const [serviceSelection, setServiceSelection] = useState<any>(null);

  let selectedBoxService =
    (stationService &&
      serviceSelection?.data?.find(
        (b: any) => b.id === parseInt(stationService.id as string)
      )) ||
    null;

  updateFormData({ checkoutPin, email });
  // let validationError = !email || !checkoutPin;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  // const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      flexGrow="1"
      flex="1"
      justifyContent="center"
      alignItems="center"
      style={{ marginBottom: 90 }}
    >
      <Box mb={4} textAlign="center">
        {/* {stationLogoUrl && (
          <img
            src={stationLogoUrl}
            style={{
              alignSelf: "center",
              width: 130,
              height: 130,
              marginBottom: 30,
              borderRadius: "50%",
            }}
          />
        )} */}

        <Typography
          align="center"
          variant="h3"
          gutterBottom
          style={{
            fontSize: "2.2rem",
          }}
        >
          {station.name}
        </Typography>
        {!!station.address ? (
          <Box
            mt={3}
            mb={2}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography variant="h6" gutterBottom className="text-center">
              {station.address}
            </Typography>
          </Box>
        ) : (
          <></>
        )}
        <Typography
          variant="caption"
          style={{ textAlign: "center" }}
          display="block"
          gutterBottom
        >
          {ls.areYouThereOrHere}
        </Typography>
        <Typography
          variant="button"
          style={{ textAlign: "center" }}
          display="block"
          gutterBottom
          onClick={() => {
            goBack();
          }}
        >
          {ls.goBack}
        </Typography>
      </Box>

      {!serviceSelection && (
        <Box
          mt={5}
          mb={5}
          display="flex"
          width="100%"
          justifyContent="around"
          flexDirection="row"
        >
          <ServiceBox
            data={{
              icon: "lock",
              boxBackground:
                "linear-gradient(135deg, #6cc981 0%, #39964c 100%)",
              title: withBoxSelection ? ls.box : ls.locker,
              message: ls.thankYouMessage1,
            }}
            action={() => {
              if (withBoxSelection) {
                setServiceSelection({
                  type: "Box",
                  title: ls.boxSelectionTitle,
                  selectionTitle: ls.boxSelectionHint,
                  confirmationMessage: "Conf.....",
                  actionTitle: ls.sendBoxPin,
                  data: station.boxes
                    .filter((b: any) => !!b.online)
                    .sort((a: any, b: any) => a.serviceIdx - b.serviceIdx),
                });
              }
              if (
                stationType === "ONLY_LOCKERS" ||
                stationType === "SOCKETS_AND_LOCKERS"
              ) {
                setServiceSelection({
                  type: "Locker",
                  title: ls.lockerSelectionTitle,
                  selectionTitle: ls.lockerSelectionHint,
                  confirmationMessage: "Conf.....",
                  actionTitle: ls.sendLockerPin,
                  data: station.lockers
                    .filter((b: any) => !!b.online)
                    .sort((a: any, b: any) => a.serviceIdx - b.serviceIdx),
                });
              } else if (stationType === "ONLY_LOCKERS_NOPIN") {
                setServiceSelection({
                  type: "Locker",
                  title: ls.lockerSelectionTitle,
                  selectionTitle: ls.lockerSelectionHint,
                  confirmationMessage: "Conf.....",
                  actionTitle: ls.openLocker,
                  data: station.lockers
                    .filter((b: any) => !!b.online)
                    .sort((a: any, b: any) => a.serviceIdx - b.serviceIdx),
                });
              }
            }}
            back={() => {}}
          ></ServiceBox>
          {hasToolsBox && (
            <ServiceBox
              data={{
                icon: "handyman",
                title: "Service",
                message: ls.thankYouMessage1,
              }}
              action={() => {
                setServiceSelection({
                  type: "Attrezzi",
                  title: ls.toolsSelectionTitle,
                  selectionTitle: ls.toolsSelectionHint,
                  actionTitle: ls.sendToolsActivationCode,
                  confirmationMessage: "Conf.....",
                  data: station.tools.filter((b: any) => !!b.online),
                });
              }}
              back={() => {}}
            ></ServiceBox>
          )}
        </Box>
      )}

      {serviceSelection && (
        <Box>
          <Typography align="center" variant="h5" gutterBottom>
            {serviceSelection.title}
          </Typography>
          <Box
            mb={3}
            height="40px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="row"
            onClick={() => {
              setServiceSelection(null);
            }}
          >
            <Icon>keyboard_backspace</Icon>
            <Typography style={{ marginLeft: 5 }} align="center" variant="h6">
              {ls.cancel}
            </Typography>
          </Box>
        </Box>
      )}

      {serviceSelection && (
        <Box
          mt={3}
          mb={3}
          display="flex"
          justifyContent="center"
          alignContent="center"
          flexDirection="column"
        >
          <Typography variant="body1" gutterBottom>
            {serviceSelection?.selectionTitle}
          </Typography>
          <ServiceSelection
            options={serviceSelection?.data?.map((box: any) => ({
              key: box.id,
              value: box.name,
              orderField: !!orderServiceBy ? box[orderServiceBy] : null,
            }))}
            orderBy={orderServiceBy}
            selectedValue={stationService?.id + "" || ""}
            // hintText='Selezionare il box'
            emptySelectionText={ls.nothing}
            onChange={(event: any) => {
              let value = event?.target?.value;
              let selected = serviceSelection?.data.find((srv: any) => {
                return parseInt(srv.id) === parseInt(value);
              });
              // console.log('Selected', selected);
              // console
              select(selected);
            }}
          ></ServiceSelection>
        </Box>
      )}

      {!hasPayment &&
        stationService &&
        !is_bugg &&
        selectedBoxService?.checkout === false && (
          <Box mt={3} mb={5} display="flex" flexDirection="column">
            <FormLabel style={{ marginBottom: 8 }} component="legend">
              {!!noEmail ? ls.insert4DigiCode : ls.insertYourEmail}
            </FormLabel>
            <Input
              error={!!emailError}
              className="slim"
              value={email}
              fullWidth
              type={noEmail ? "text" : "email"}
              inputProps={
                noEmail
                  ? {
                      pattern: "[0-9]{4}",
                      maxlength: "4",
                    }
                  : undefined
              }
              placeholder={!!noEmail ? ls.insert4DigiCode : ls.emailPlaceholder}
              required={true}
              onChange={(event: any) => {
                // console.log('event', event);
                setEmail(event?.target?.value);
                // updateFormData({ email });
              }}
            ></Input>
            <FormHelperText>{emailError}</FormHelperText>
          </Box>
        )}

      {stationService && !noEmail && selectedBoxService?.checkout === true && (
        <Box mt={3} mb={5} display="flex" flexDirection="column">
          <FormLabel style={{ marginBottom: 8 }} component="legend">
            {ls.yourPinCode}
          </FormLabel>
          <Input
            className="slim"
            value={checkoutPin}
            fullWidth
            type={"text"}
            placeholder={ls.insertYourPinCode}
            required={true}
            onChange={(event: any) => {
              console.log("event", event);
              setCheckoutPin((event?.target?.value || "").toUpperCase());
              // updateFormData({ checkoutPin });
            }}
          ></Input>
          <FormHelperText>{ls.pinCodeHelperMessage}</FormHelperText>
        </Box>
      )}

      {
        // raccolgo le infomazioni aggiuntive se sto cercando di riservere un servizio
        !!serviceSelection && stationService && !!additionalInfo && (
          <Box display="flex" flexDirection="column">
            <Typography align="center" variant="body1" gutterBottom>
              {ls.additionalInfo_title}
            </Typography>
            {AdditionalInfo({
              formData: {},
              additionalInfo: additionalInfo,
              updateFormData: (updated: any) => {
                setAdditionalInfo({
                  ...additionalInfo,
                  ...updated,
                });
              },
            })}
          </Box>
        )
      }

      {!hasPayment &&
        stationService &&
        selectedBoxService?.checkout === false && (
          <Box mt={!!noEmail ? 1 : 3} mb={5}>
            <Button
              variant="contained"
              size="large"
              disabled={!is_bugg && (!!emailError || !email)}
              color="primary"
              onClick={() => {
                confirm();
              }}
              fullWidth
            >
              {(selectedBoxService?.checkout === true &&
                ls.completeCheckoutWithPin) ||
                serviceSelection.actionTitle}
            </Button>
          </Box>
        )}
      {!hasPayment &&
        stationService &&
        selectedBoxService?.checkout === true && (
          <Box mt={3} mb={5}>
            <Button
              variant="contained"
              size="large"
              disabled={!checkoutPin}
              color="primary"
              onClick={() => {
                confirm();
              }}
              fullWidth
            >
              {(selectedBoxService?.checkout === true &&
                ls.completeCheckoutWithPin) ||
                (withBoxSelection ? ls.sendBoxPin : ls.sendLockerPin)}
            </Button>
          </Box>
        )}
      {!!serviceSelection && hasPayment && stationService && (
        <Box mt={3} mb={5}>
          <Button
            variant="contained"
            size="large"
            disabled={!stationService}
            color="primary"
            onClick={() => {
              confirm();
            }}
            fullWidth
          >
            {ls.proceed}
          </Button>
        </Box>
      )}

      {/*  {hasToolsBox && (
        <>
          <Fab
            aria-controls='simple-menu'
            aria-haspopup='true'
            style={{
              position: 'fixed',
              bottom: 20,
              right: 16,
              backgroundColor: '#6b6b6b',
            }}
            onClick={handleClick}
            aria-label='edit'
          >
            <BuildIcon />
          </Fab>
          <Menu
            id='simple-menu'
            anchorEl={anchorEl}
            keepMounted
            anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {tools.map((tool: any, idx: number) => {
              return (
                <MenuItem
                  key={'service-' + idx}
                  onClick={() => {
                    // openServiceBox && openServiceBox(tool);
                    confirm();
                    handleClose();
                  }}
                >
                  {'Apri il ' + tool.name}
                </MenuItem>
              );
            })}
          </Menu>
        </>
      )} */}
    </Box>
  );
}

export default StationWithLockers;

export class It {
  welcomeMessage = "Benvenuti in";
  continue = "Continua";
  cancel = "Annulla";
  confirm = "Conferma";
  proceed = "Procedi";
  retry = "Riprova";
  search = "Cerca";
  nothing = "Nessuno";
  userHint1 = "Per continuare, inserisci il codice della colonnina di ricarica";
  insertPoDCode = "CODICE COLONNINA";
  recharge = "Ricarica";
  meters = "metri";
  km = "km";
  currency = {
    eur: "€",
    usd: "$",
  };
  emailPlaceholder = "La tua @email";
  box = "Box";
  locker = "Lucchetto";
  boxSelectionTitle = "Box della stazione";
  boxSelectionHint = "Seleziona il box";
  toolsSelectionTitle = "Attrezzi della stazione";
  toolsSelectionHint = "Seleziona il service attrezzi";
  sendToolsActivationCode = "INVIA CODICE SERVICE";
  lockerSelectionTitle = "Lucchetto della stazione";
  lockerSelectionHint = "Seleziona il lucchetto";
  sendBoxPin = "Invia codice box";
  sendLockerPin = "Invia codice lucchetto";
  boxPinSentMessage =
    "A breve riceverai un'email con il codice per aprire il tuo box";
  lockerPinSentMessage =
    "A breve riceverai un'email con il codice per aprire il tuo lucchetto";
  socketPinSentMessage =
    "A breve riceverai un'email con il codice per attivare la presa";
  thankYouMessage1 = "Grazie per averci scelto, arrivederci!";
  somethingWentWrong = "Ci scusiamo, qualcosa è andato storto :(";
  genericError = "Si è verificato un errore";
  operationSuccessToolsBox =
    "Operazione riuscita, il box degli attrezzi dovrebbe essere aperto";
  warningStationOffline =
    "ATTENZIONE: Siamo spiacenti ma la stazione è attualmente OFFLINE";
  completeCheckoutWithPin = "Ritira i tuoi oggetti";
  howToCompleteCheckout =
    "Per ritirare i tuoi oggetti leggi nuovamente il qrcode e segui la procedura guidata.";
  otherwise = "Oppure";
  goBack = "Torna indietro";
  chargingPoint = "Colonnina di ricarica";
  chooseTheSocket = "Scegli la presa";
  pinCodeHelperMessage = "È questo il tuo pin?";
  insertYourPinCode = "Inserisci il pin ricevuto";
  yourPinCode = "Il tuo Codice PIN";
  insertYourEmail = "Inserisci la tua Email";
  areYouThereOrHere = "Non ti trovi qui?";
  emailNotValid = "Email non valida";
  confirmations = {
    conf1: "Hai selezionato la stazione di ricarica",
    conf2: "Conferma per continuare.",
    conf3: "Hai selezionato la colonnina di ricarica",
    conf4: "e la presa",
  };
  insertRechargeTime = "Inserisci il tempo di ricarica";
  minutes = "Minutaggio";
  congratulationsBoxOpened =
    "Complimenti! ora il tuo box dovrebbe essere aperto";
  congratulationsActivationCompleted =
    "Complimenti! Attivazione effettuata con successo";
  ifBoxNotOpened =
    "Non si è aperto il lucchetto o il box? Ritenta da qui e prova ad estrarre la catena entro 6 secondi";
  ifSocketNotActivated = "Non si è attivata la Presa? Ritenta da qui";
  howToCompleteCheckout2 =
    "Nota bene: Al termine del tempo impostato per la ricarica la presa verrà disattivata";
  additionalInfo_title = "Imposta le informazioni aggiuntive dello stallo";
  additionalInfo_ua = "UA";
  additionalInfo_prt = "PRT";
  choosePaymentOption = "Scegli tra le opzioni";
  preconfiguredOption_1 = "1h = 4 €";
  preconfiguredOption_2 = "2h = 5,50 €";
  preconfiguredOption_3 = "4h = 8,50 €";
  preconfiguredOption_4 = "6h = 10,50 €";
  preconfiguredOption_5 = "10h = 13,50 €";
  preconfiguredOption_6 = "12h = 15,00 €";
  preconfiguredOption_3_1 = "30 min = 1,00 €";
  preconfiguredOption_3_2 = "60 min = 1,50 €";
  preconfiguredOption_3_3 = "90 min = 2,00 €";
  preconfiguredOption_3_4 = "120 min = 2,50 €";
  preconfiguredOption_5_1 = "30 min = 0,50 €";
  preconfiguredOption_5_2 = "60 min = 1,00 €";
  preconfiguredOption_5_3 = "90 min = 1,50 €";
  preconfiguredOption_5_4 = "120 min = 2,00 €";
  paymentOkRechargeStarted =
    "Collega la presa, la tua ricarica inizierà a breve.";
  paymentOkEmailPINSentLocker =
    "A breve riceverai un'email con il codice per aprire il tuo lucchetto";
  paymentOkEmailPINSentBox =
    "A breve riceverai un'email con il codice per aprire il tuo box";
  paymentOkEmailPINSentSocket =
    "A breve riceverai un'email con il codice per attivare la ricarica";
  doUWantARecharge = "Vuoi ricaricare?";
  station = "Stazione";
  qrCodeScan1 = "Avvicinati alla stazione di ricarica e trova il QR Code";
  qrCodeScan2 =
    "Una volta scansionato il codice potrai accedere alla stazione ed utilizzare i servizi dispoibili";
  qrCodeScanSuggestion1 = "Avvicina il QR Code di una colonnina";
  qrCodeScanNotAvailable =
    '"Scanner QR Code non disponible, assicurati di aver concesso l\'autorizzazione per la fotocamera"';
  cardOwner = "Intestatario";
  email = "Email";
  pay = "Paga";
  processInProgress = "Caricamento...";
  serviceCheckedOut = "Occupato";
  notAvailable = "Non disponibile";
  openLocker = "Apri Lucchetto";
  insert4DigiCode = "Inserisci codice a 4 cifre";
  invalidCode = "Codice non valido";
}

import { Container } from '@material-ui/core';
import React, { PropsWithoutRef } from 'react';
import { useRoutes } from 'react-router';
import './App.css';
import { useLoader } from './hooks/loaderState';
import routes from './routes';

function App() {
  const isLoading = useLoader();
  // console.log('useLoader', isLoading);
  const routing = useRoutes(routes);

  // console.log('ENV', process.env);

  return (
    <Container
      style={{
        minHeight: '100vh',
        padding: 0,
      }}
      maxWidth='md'
    >
      {routing}
      <LoadingIndicator loading={isLoading}></LoadingIndicator>
    </Container>
  );
}

function LoadingIndicator(props: PropsWithoutRef<{ loading?: boolean }>) {
  return (
    (!!props.loading && (
      <div className='loader-container'>
        <div className='loader-container2'>
          <div className='loader2 loader7'></div>
        </div>
      </div>
    )) ||
    null
  );
}

export default App;

import {
  makeStyles,
  Radio,
  Box,
  RadioProps,
  Typography,
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import React, { Component } from 'react';
import logo from './logo.svg';

const CheckedIcon = () => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle
      cx='8'
      cy='8'
      r='7'
      fill='#2A2B2F'
      stroke='#56C26D'
      stroke-width='2'
    />
    <circle cx='8' cy='8' r='4' fill='white' />
  </svg>
);

const DefaultIcon = () => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle
      cx='8'
      cy='8'
      r='7'
      fill='#2A2B2F'
      stroke='#56C26D'
      stroke-width='2'
    />
  </svg>
);

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow:
      'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    border: '2px solid #56C26D',
    // backgroundImage:
    //   'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto #56C26D',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      border: '2px solid rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    borderRadius: '50%',
    backgroundColor: '#56C26D',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 38%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#56C26D',
    },
  },
  checkout: {
    color: '#ccc',
  },
  notAvailable: {
    color: '#ccc',
  },
});

function StyledRadio(
  props: RadioProps & { label: string; status?: string; statusMessage?: string }
) {
  const classes: any = useStyles();

  const { label, status, statusMessage, ...radioProps } = props;

  // return (
  //   <Radio
  //     className={classes.root}
  //     icon={<DefaultIcon className={classes.icon}></DefaultIcon>}
  //     checkedIcon={<CheckedIcon></CheckedIcon>}
  //     {...props}
  //   />
  // );
  return (
    // <Radio
    //   className={classes.root}
    //   icon={<span className={classes.icon} />}
    //   checkedIcon={
    //     <span className={`${classes.icon}, ${classes.checkedIcon}`} />
    //   }
    //   {...props}
    // />
    <Box display='flex' flexDirection='column'>
      <Box display='flex' flexDirection='row' alignItems='center'>
        <Radio
          className={classes.root}
          icon={<span className={classes.icon} />}
          checkedIcon={
            <span className={`${classes.icon}, ${classes.checkedIcon}`} />
          }
          {...{ label, ...radioProps }}
        />
        <Typography variant='body1' className='text-center'>
          {label}
        </Typography>
        {!!statusMessage && (
          <Typography
            variant='caption'
            style={{ marginLeft: 12 }}
            className={`text-center, ${classes[status as any]}`}
          >
            {statusMessage}
          </Typography>
        )}
      </Box>
    </Box>
  );
}

export default StyledRadio;

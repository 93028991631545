import { LoaderService } from '@hooks/loaderState';
import { Button, Container, Typography } from '@material-ui/core';
import React, { useRef, useState } from 'react';
import { animated, useChain, useTransition } from 'react-spring';
import Logo from '@assets/images/logo.svg';
import { ls } from '@i18n/translations';
import { Navigate, useNavigate } from 'react-router';

function LandingPage() {
  const navigate = useNavigate();
  const [show, set] = useState(false);

  const logoRef = useRef<any>();
  const messageRef = useRef<any>();
  const buttonRef = useRef<any>();

  const logoTransitions = useTransition(show, 'logo', {
    ref: logoRef,
    from: {
      position: 'fixed',
      top: '-35%',
      right: '50%',
      transform: 'translate(50%, -50%)',
    },
    enter: {
      position: 'fixed',
      top: '45%',
      right: '50%',
      transform: 'translate(50%, -50%)',
    },
  });
  const messageTransitions = useTransition(show, 'message', {
    ref: messageRef,
    config: { duration: 1000 },
    from: {
      position: 'fixed',
      top: '35%',
      right: '50%',
      transform: 'translate(50%, -50%)',
      opacity: 0,
    },
    enter: {
      position: 'fixed',
      top: '35%',
      right: '50%',
      transform: 'translate(50%, -50%)',
      opacity: 1,
    },
  });

  const buttonTransitions = useTransition(show, 'button', {
    ref: buttonRef,
    config: { duration: 1000 },
    from: {
      position: 'fixed',
      top: '65%',
      right: '50%',
      transform: 'translate(50%, -50%)',
      opacity: 0,
    },
    enter: {
      position: 'fixed',
      top: '65%',
      right: '50%',
      transform: 'translate(50%, -50%)',
      opacity: 1,
    },
  });

  useChain(
    [logoRef as any, messageRef as any, buttonRef as any],
    [1, 0.1, 1.8] /*1000*/
  );

  setTimeout(() => {
    set(true);
  }, 100);

  return (
    <Container
      maxWidth='sm'
      style={{
        position: 'relative',
        height: '100vh',
      }}
    >
      {logoTransitions.map(
        ({ item, key, props }) =>
          item && (
            <animated.div key={key} style={props}>
              <Logo />
            </animated.div>
          )
      )}

      {messageTransitions.map(
        ({ item, key, props }) =>
          item && (
            <animated.span key={key} style={props}>
              <Typography
                variant='body1'
                align='center'
                component='h4'
                gutterBottom
              >
                {ls.welcomeMessage}
              </Typography>
            </animated.span>
          )
      )}

      {buttonTransitions.map(
        ({ item, key, props }) =>
          item && (
            <animated.div key={key} style={props}>
              <Button
                className='huge'
                size='large'
                color='primary'
                onClick={() => {
                  LoaderService.startLoader();
                  navigate('/app');
                  setTimeout(() => {
                    LoaderService.stopLoader();
                  }, 600);
                }}
              >
                {ls.continue}
              </Button>
            </animated.div>
          )
      )}
    </Container>
  );
}

export default LandingPage;

import React from "react";

export const reducer = (state: any, action: { type: string; params: any }) => {
// console.log("[Reducer]", state, action);
  switch (action?.type) {
    // case "toggle_button":
    //   return {
    //     ...state,
    //     active: !state.active,
    //   };

    default:
      return { ...state, ...(action?.params || {})};
  }
};

export const initialState = {
  active: false,
  stationDetail: null
};

export const AppContext = React.createContext({
  state: initialState,
  dispatch: () => null,
});

export const AppStateProvider = ({ children }: any) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    <AppContext.Provider value={[state, dispatch] as any}>
      {children}
    </AppContext.Provider>
  );
};

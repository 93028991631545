export function round(value: number, precision: number = 1, base?: number) {
  var multiplier = Math.pow(base || 10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}

export function checkStationOnline(station: any) {
  if (
    station &&
    (station.type === "STATION_WITH_LOCKERS" ||
      station.type === "SINGLE_BOX_STATION")
  ) {
    return station.boxes && station.boxes.every((b: any) => !!b.online);
  } else if (
    station &&
    (station.type === "ONLY_LOCKERS" || station.type === "ONLY_LOCKERS_NOPIN")
  ) {
    return station.lockers && station.lockers.every((b: any) => !!b.online);
  } else {
    return station?.sockets && station.sockets.every((b: any) => !!b.online);
  }
}

export function ValidateEmail(mail: string) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (re.test(mail)) {
    return true;
  }
  // alert('You have entered an invalid email address!');
  return false;
}

export function Validate4digitCode(mail: string) {
  const re = /^[0-9]{4}$/;
  if (re.test(mail)) {
    return true;
  }
  // alert('You have entered an invalid email address!');
  return false;
}

export function getSocketShortName(_socket: string) {
  switch (`${_socket}`) {
    case "0":
      return "1";
    case "1":
      return "2";
    case "2":
      return "3";
    case "3":
      return "4";
    default:
      return "";
  }
}

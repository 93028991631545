import Page from '@components/Page';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { ClientService } from '@services/clientApi';
import { StationsService } from '@services/stationsService';
import React, { useEffect, useState } from 'react';
import { StationsStore, useStationDetail } from '@hooks/stationsState';
import { useLocation, useParams, useNavigate } from 'react-router';
import { LoaderService } from '@hooks/loaderState';
import { ls } from '@i18n/translations';
const moment = require('moment');

const useStyles = makeStyles((theme) => ({
  page: {
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

// TODO: recuperare paymentIntent
var amount: any = null;

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
function laodCheckoutData(): Promise<any> {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      try {
        let checkoutData = JSON.parse(
          sessionStorage.getItem('checkoutData') || ''
        );
        resolve(checkoutData);
      } catch (err) {
        resolve('');
      }
    }, 100);
  });
}

async function loadDetail(stationId: string) {
  LoaderService.startLoader();
  let checkoutData = await laodCheckoutData();

  let _paymentInfo = null;
  ClientService.getPaymentInfo(checkoutData?.intentId)
    .then((response) => {
      _paymentInfo = response;
      return StationsService.getDetail(stationId, {});
    })
    .then((station) => {
      // console.log('POD DATA...', station);
      StationsStore.updateStation(station);
      // unitCost = parseFloat(station.unitCost);
      // merchantId = station.merchantId;
      setTimeout(() => {
        LoaderService.stopLoader();
      }, 800);
    })
    .catch(() => {
      setTimeout(() => {
        LoaderService.stopLoader();
      }, 800);
    });
}

const CARD_OPTIONS = {
  iconStyle: 'solid',
  style: {
    base: {
      iconColor: 'rgba(255, 255, 255, 0.5)',
      color: '#000',
      padding: '11px',
      backgroundColor: '#fff',
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '1.2rem',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': { color: '#fce883' },
      '::placeholder': { color: '#a3a3a3' },
    },
    invalid: {
      iconColor: '#ffc7ee',
      color: '#ffc7ee',
    },
  },
} as any;

var loaded = false;
var paymentInfo: any = null;

const StatusPage = () => {
  let { deviceId, socketIdx } = useParams();

  const classes = useStyles();

  const location = useLocation();
  const [stationService, setStationService] = useState(null);
  const [showResult, setShowResult] = useState(false);

  const station = useStationDetail();

  useEffect(() => {
    loaded = false;
    loadDetail(deviceId).then((_paymentInfo) => {
      loaded = true;
      paymentInfo = _paymentInfo;
      setShowResult(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  // console.log('STATION', station);
  if (!stationService && !!station) {
    let service = [
      ...(station?.boxes || []),
      ...(station?.lockers || []),
      ...(station?.sockets || []),
    ].find((srv) => parseInt(srv.id) === parseInt(socketIdx));

    console.log('STATION SERVICE', service);
    if (!!service) {
      setStationService(service);
    }
  }

  return (
    <Page className={classes.page} title='Payment'>
      <Box
        display='flex'
        flexDirection='column'
        height='100%'
        flexGrow='1'
        flex='1'
        justifyContent='center'
        alignItems='center'
      >
        {(station && showResult && (
          <ResultView
            data={{
              station,
              stationService,
            }}
          />
        )) || (
          <div className='loader-container'>
            <div className='loader-container2'>
              <div className='loader2 loader7'></div>
            </div>
          </div>
        )}
      </Box>
    </Page>
  );
};

var processing = false;

function getTargetTime(_paymentInfo: any) {
  // if (sessionStorage.getItem('targetTime')) {
  //   let targetTime = sessionStorage.getItem('targetTime');
  //   return targetTime;
  // }
  if (_paymentInfo) {
    // let newDate = new Date();
    // let duration = moment.duration(_paymentInfo.time, 'seconds');

    // let hours = duration.get('hour');
    // let minutes = duration.get('minutes');
    // newDate.setHours(newDate.getHours() + hours);
    // newDate.setMinutes(newDate.getMinutes() + minutes);

    // let targetTime = moment(newDate).format('HH:mm');
    // return targetTime;

    return moment().add(_paymentInfo.time, 'seconds').format('HH:mm');
  }
  return '-';
}

const ResultView = (
  props: React.PropsWithoutRef<{
    className?: string;
    data?: {
      station: any;
      stationService: any;
      // result: { error?: boolean; message: string };
    };
  }>
) => {
  const { data } = props;

  // const result = data?.result;
  const station = data?.station;
  const stationService = data?.stationService;

  let message1 = ls.ifBoxNotOpened;
  if (station?.type === 'BASIC') {
    message1 = ls.paymentOkRechargeStarted;
  } else if (stationService?.type === 'SOCKET') {
    message1 = ls.paymentOkEmailPINSentSocket;
  } else if (stationService?.type === 'LOCKER') {
    message1 = ls.paymentOkEmailPINSentLocker;
  } else if (stationService?.type === 'BOX') {
    message1 = ls.paymentOkEmailPINSentBox;
  }
  let message2 = ls.thankYouMessage1;
  // if (stationService?.device?.type === 'SOCKET') {
  //   message2 = ls.howToCompleteCheckout2;
  // }

  const navigate = useNavigate();

  const [paymentSucceeded, setPaymentSucceeded] = useState(true);

  let newDate = new Date();

  let targetTime = getTargetTime(paymentInfo);

  let paymentSucceededView = (
    <Box
      display='flex'
      flexDirection='column'
      height='100%'
      flexGrow='1'
      flex='1'
      justifyContent='center'
      alignItems='center'
    >
      <div className='infoBoxContainer'>
        <Box
          className='infoBox'
          display='flex'
          flexDirection='column'
          flexGrow='1'
          flex='1'
          justifyContent='center'
          alignItems='center'
        >
          <Typography variant='caption' display='block' gutterBottom>
            {message1}
          </Typography>
          <Typography variant='caption' display='block' gutterBottom>
            {message2}
          </Typography>
        </Box>
      </div>

      <Box>
        <Typography
          variant='caption'
          display='block'
          style={{ textAlign: 'center' }}
          gutterBottom
        >
          {ls.doUWantARecharge}
        </Typography>
        <div
          onClick={() => {
            navigate(`/app`);
          }}
        >
          <Typography variant='button' display='block' gutterBottom>
            {ls.goBack}
          </Typography>
        </div>
      </Box>
    </Box>
  );

  return paymentSucceeded ? (
    paymentSucceededView
  ) : (
    <ErrorMessage>{'Si è verificato un errore'}</ErrorMessage>
  );
};

function ErrorMessage(
  props: React.PropsWithoutRef<{
    children?: any;
  }>
) {
  const { children } = props;

  return (
    <div className='ErrorMessage' role='alert'>
      <svg width='16' height='16' viewBox='0 0 17 17'>
        <path
          fill='#FFF'
          d='M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z'
        />
        <path
          fill='#871201'
          d='M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z'
        />
      </svg>
      {children}
    </div>
  );
}

export default StatusPage;

import React, { useEffect, useState } from 'react';
import { loadStripe, Stripe, StripeCardElement } from '@stripe/stripe-js';
import {
  CardElement,
  useStripe,
  useElements,
  Elements,
} from '@stripe/react-stripe-js';
import { Box, Button, Input, Typography, makeStyles } from '@material-ui/core';
import Page from '@components/Page';
import { currentLang, ls } from '@i18n/translations';
import { useLocation, useNavigate, useParams } from 'react-router';
import { ClientService } from '@services/clientApi';
const CurrencyFormat = require('react-currency-format');
const moment = require('moment');

const useStyles = makeStyles((theme) => ({
  page: {
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

// TODO: recuperare paymentIntent
var amount: any = null;

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
function loadCheckoutData(): Promise<any> {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      try {
        let checkoutData = JSON.parse(
          sessionStorage.getItem('checkoutData') || ''
        );
        resolve(checkoutData);
      } catch (err) {
        resolve('');
      }
    }, 830);
  });
}

var stripeLoaded = false;
var _stripe: any = {} as Stripe;

function loadStripeForm() {
  return loadCheckoutData().then((checkoutData: any) => {
    if (!checkoutData?.connectedAccount) {
      console.log('[Error] missing connectedAccount...');
      return Promise.resolve(null);
    } else {
      amount = checkoutData.amount;
      return loadStripe(process.env.REACT_APP_STRIPE_PK as string, {
        locale: currentLang,
        apiVersion: '2020-08-27',
        stripeAccount: checkoutData?.connectedAccount,
      }).then((__stripe) => {
        _stripe = __stripe;
        stripeLoaded = true;
        return _stripe;
      });
    }
  });
}

const CARD_OPTIONS = {
  iconStyle: 'solid',
  style: {
    base: {
      iconColor: 'rgba(255, 255, 255, 0.5)',
      color: '#000',
      padding: '11px',
      backgroundColor: '#fff',
      fontSize: '1.2rem',
      fontWeight: 400,
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': { color: '#fce883' },
      '::placeholder': { color: '#a3a3a3' },
    },
    invalid: {
      iconColor: '#ed0700',
      color: '#ed0700',
    },
  },
} as any;

const StripeForm = () => {
  const classes = useStyles();
  const [loadForm, setFormLoad] = useState(false);

  const location = useLocation();

  useEffect(() => {
    setFormLoad(false);
    stripeLoaded = false;
    loadStripeForm().then(() => {
      setFormLoad(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return _stripe && loadForm ? (
    <Page className={classes.page} title='Payment'>
      <Box
        display='flex'
        flexDirection='column'
        height='100%'
        flexGrow='1'
        flex='1'
        justifyContent='center'
        alignItems='center'
      >
        <Elements stripe={_stripe}>
          <PaymentForm />
        </Elements>
      </Box>
    </Page>
  ) : (
    <Page className={classes.page} title='Payment'>
      <Box
        display='flex'
        flexDirection='column'
        height='100%'
        flexGrow='1'
        flex='1'
        justifyContent='center'
        alignItems='center'
      >
        <div className='loader-container'>
          <div className='loader-container2'>
            <div className='loader2 loader7'></div>
          </div>
        </div>
      </Box>
    </Page>
  );
};

var processing = false;

function Field(props: any) {
  const {
    label,
    id,
    type,
    placeholder,
    required,
    autoComplete,
    value,
    onChange,
  } = props;

  return (
    <Box className='FormRow' mb={2}>
      <Input
        className='slim'
        value={value}
        id={id}
        fullWidth
        type={type}
        placeholder={placeholder}
        required={required}
        autoComplete={autoComplete}
        onChange={onChange}
      ></Input>
      {/* <input
        className='FormRowInput'
        id={id}
        type={type}
        placeholder={placeholder}
        required={required}
        autoComplete={autoComplete}
        value={value}
        onChange={onChange}
      /> */}
    </Box>
  );
}

function setRechargeTargetTime(intentId: string) {
  return ClientService.getPaymentInfo(intentId)
    .then((_paymentInfo) => {
      if (_paymentInfo) {
        let newDate = new Date();
        let duration = moment.duration(_paymentInfo.time, 'seconds');

        let hours = duration.get('hour');
        let minutes = duration.get('minutes');
        newDate.setHours(newDate.getHours() + hours);
        newDate.setMinutes(newDate.getMinutes() + minutes);

        let targetTime = moment(newDate).format('HH:mm');
        sessionStorage.setItem('targetTime', targetTime);
      }
    })
    .catch(() => {});
}

const PaymentForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  let { deviceId, socketIdx } = useParams();
  const navigate = useNavigate();

  const handleSubmit = async (event: any) => {
    // Block native form submission.
    event.preventDefault();
    processing = true;

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    let checkoutData = JSON.parse(
      sessionStorage.getItem('checkoutData') as string
    );
    let client_secret = checkoutData?.client_secret;

    // Use your card Element with other Stripe.js APIs
    // const { error, paymentMethod } = await stripe.createPaymentMethod({
    //   type: 'card',
    //   card: cardElement as StripeCardElement,
    // });

    // console.log(error);

    stripe
      .confirmCardPayment(client_secret, {
        payment_method: {
          card: cardElement as StripeCardElement,
          billing_details: {
            name: billingDetails.name,
            email: billingDetails.email,
          },
        },
      })
      .then((result) => {
        console.log('STRIPE RESULT', result);
        if (result.error) {
          console.log(result.error.message);
        } else {
          // The payment has been processed!
          if (result.paymentIntent?.status === 'succeeded') {
            setRechargeTargetTime(result.paymentIntent?.id);

            navigate(`/app/stations/${deviceId}/status/${socketIdx}`);
          }
        }
      })
      .catch((error) => {
        if (error) {
          console.log('[error]', error);
        }
      });
  };

  const [billingDetails, setBillingDetails] = useState({} as any);

  return (
    <form className='Form' style={{ width: '100%' }} onSubmit={handleSubmit}>
      <fieldset className='FormGroup'>
        <Field
          label='Name'
          id='name'
          type='text'
          placeholder={ls.cardOwner}
          required
          autoComplete='name'
          value={billingDetails.name}
          onChange={(e: any) => {
            setBillingDetails({ ...billingDetails, name: e.target?.value });
          }}
        />
        <Field
          label='Email'
          id='email'
          type='email'
          placeholder={ls.email}
          required
          autoComplete='email'
          value={billingDetails.email}
          onChange={(e: any) => {
            setBillingDetails({ ...billingDetails, email: e.target?.value });
          }}
        />
      </fieldset>
      <fieldset className='FormGroup'>
        <Box className='FormRow' mb={2}>
          <CardElement options={CARD_OPTIONS} />
        </Box>
      </fieldset>
      {/* {error && <ErrorMessage>{error.message}</ErrorMessage>} */}
      <SubmitButton processing={processing} disabled={!stripe}>
        <Typography
          variant='button'
          display='inline'
          style={{ marginRight: 5 }}
        >
          {ls.pay}
        </Typography>
        {amount && (
          <CurrencyFormat
            value={'' + amount}
            fixedDecimalScale={true}
            decimalScale={2}
            suffix={ls.currency['eur']}
            displayType='text'
          ></CurrencyFormat>
        )}
      </SubmitButton>
    </form>
  );
};

function SubmitButton(
  props: React.PropsWithoutRef<{
    processing?: any;
    error?: any;
    children?: any;
    disabled?: boolean;
  }>
) {
  const { processing, error, children, disabled } = props;

  return (
    <Button
      className={`SubmitButton ${error ? 'SubmitButton--error' : ''}`}
      variant='contained'
      size='large'
      type='submit'
      color='primary'
      fullWidth
      disabled={processing || disabled}
    >
      {processing ? ls.processInProgress : children}
    </Button>
  );
}

export default StripeForm;

import React, { useState, useEffect } from "react";
import { AppContext } from "src/state/AppState";

class StationsState {
  stateHandler: any | null;

  setEffect(handler: ((status: any[]) => void) | null) {
    this.stateHandler = handler;
  }

  updateStations(stations: any[]) {
    this.stateHandler && this.stateHandler(stations);
  }
  updateStation(station: any) {
    this.stateHandler && this.stateHandler(station);
  }
}

export const StationsStore = new StationsState();

export function useStations() {
  const [stations, setStations] = useState<any[] | null>(null);

  useEffect(() => {
    StationsStore.setEffect((stations: any[]) => {
      setStations(stations);
    });
    return () => {
      // Cleaninig Effect...
      // console.log('Cleaninig useStations Effect...');
      StationsStore.setEffect(null);
    };
  });

  return stations;
}

export function useStationDetail() {
  const [station, setStation] = useState<any | null>(null);
  const [state, dispatch] = React.useContext(AppContext) as any;

  useEffect(() => {
    if (!StationsStore.stateHandler) {
      StationsStore.setEffect((station: any) => {
        // console.log("SETTING STATION");
        setStation(station);
        dispatch({ type: "any", params: { stationDetail: station } });
      });
    }
    return () => {
      // Cleaninig Effect...
      // console.log('Cleaninig useStationDetail Effect...');
      StationsStore.setEffect(null);
    };
  });

  return station;
}

import MessageBox from '@components/MessageBox';
import Page from '@components/Page';
import { LoaderService } from '@hooks/loaderState';
import { ls } from '@i18n/translations';
import {
  Box,
  Button,
  makeStyles,
  Snackbar,
  Typography,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { ClientService } from '@services/clientApi';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';

const useStyles = makeStyles((theme) => ({
  page: {
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

var loaded = false;
var error = false;
var formData = {} as any;

const PinActivationPage = () => {
  const classes = useStyles();

  const location = useLocation();

  const [stationService, setStationService] = useState(null);
  const [showResult, setShowResult] = useState(false);
  const [showSnack, setShowSnack] = useState({
    open: false,
  } as any);

  const [result, setResult] = useState<any>({
    error: false,
    message: 'Operazione in corso..',
  });

  let { uuid, pin } = useParams();

  formData = {
    uuid,
    pin,
  };

  async function postData(data: any) {
    localStorage.setItem('checkoutID', data.uuid);
    localStorage.setItem('checkoutPIN', data.pin);

    return ClientService.activateWithPin(data.uuid, data.pin)
      .then((result: any) => {
        loaded = true;
        let _stationService = result?.data?.data?.deviceService;
        setStationService(_stationService);
        setShowResult(true);
        let deviceType = _stationService?.device?.type;
        switch(deviceType) {
          case 'SINGLE_BOX_STATION':
          case 'BOX': {
            setResult({
              message: ls.congratulationsBoxOpened,
            });
            break;
          }
          default: {
            setResult({
              message: ls.congratulationsActivationCompleted,
            });
          }
        }
        return result;
      })
      .catch((error) => {
        loaded = true;
        console.log(error?.response?.data?.data?.deviceService);
        setStationService(error?.response?.data?.data?.deviceService);
        setShowResult(true);
        setResult({
          message: ls.somethingWentWrong,
        });
        setShowSnack({
          open: true,
          severity: 'error',
          message: ls.genericError,
        });
      });
  }

  useEffect(() => {
    loaded = false;
    postData({
      uuid,
      pin,
    })
      .then((result) => {
        LoaderService.stopLoader();
        loaded = true;
      })
      .catch(() => {
        loaded = true;
        LoaderService.stopLoader();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <Page className={classes.page} title='Service activation'>
      <Box
        display='flex'
        flexDirection='column'
        height='100%'
        flexGrow='1'
        flex='1'
        justifyContent='center'
        alignItems='center'
      >
        {(showResult && (
          <ResultView
            data={{
              stationService,
              result,
            }}
            retry={() => {
              postData({
                uuid,
                pin,
              })
                .then((_paymentInfo) => {
                  LoaderService.stopLoader();
                  loaded = true;
                })
                .catch(() => {
                  LoaderService.stopLoader();
                });
            }}
          />
        )) || (
          <div className='loader-container'>
            <div className='loader-container2'>
              <div className='loader2 loader7'></div>
            </div>
          </div>
        )}
      </Box>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={showSnack.open}
        autoHideDuration={8000}
        onClose={() => {
          setShowSnack({
            open: false,
          });
        }}
      >
        <MuiAlert
          elevation={6}
          variant='filled'
          onClose={() => {
            setShowSnack({ open: false });
          }}
          severity={showSnack.severity}
        >
          {showSnack.message}
        </MuiAlert>
      </Snackbar>
    </Page>
  );
};

var processing = false;

const ResultView = (
  props: React.PropsWithoutRef<{
    className?: string;
    data?: {
      stationService: any;
      result: { error?: boolean; message: string };
    };
    retry?: () => void;
  }>
) => {
  const { data, retry } = props;
  const result = data?.result;
  const stationService = data?.stationService;

  let message1 = ls.ifBoxNotOpened;
  if (stationService?.device?.type === 'SOCKET') {
    message1 = ls.ifSocketNotActivated;
  }
  let message2 = ls.howToCompleteCheckout;
  if (stationService?.device?.type === 'SOCKET') {
    message2 = ls.howToCompleteCheckout2;
  }

  let paymentSucceededView = (
    <Box
      display='flex'
      flexDirection='column'
      height='100%'
      flexGrow='1'
      flex='1'
      justifyContent='center'
      alignItems='center'
    >
      <div className='infoBoxContainer'>
        <Box
          className='infoBox'
          display='flex'
          flexDirection='column'
          flexGrow='1'
          flex='1'
          justifyContent='center'
          alignItems='center'
        >
          <Typography variant='caption' display='block' gutterBottom>
            {result && result?.message}
          </Typography>
        </Box>
      </div>

      <Box
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
      >
        {loaded && (
          <Typography
            variant='caption'
            display='block'
            style={{ textAlign: 'center' }}
            gutterBottom
          >
            {message1}
          </Typography>
        )}
        <div>
          <Button
            style={{ marginTop: 20 }}
            variant='outlined'
            className='huge'
            size='large'
            color='primary'
            onClick={() => {
              retry && retry();
            }}
          >
            {ls.retry}
          </Button>
        </div>
      </Box>
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        mt={5}
      >
        {loaded && (
          <Typography
            variant='caption'
            display='block'
            style={{ textAlign: 'center' }}
            gutterBottom
          >
            {message2}
          </Typography>
        )}
      </Box>
    </Box>
  );

  return result ? (
    paymentSucceededView
  ) : (
    <MessageBox
      data={{
        severity: 'danger',
        message: ls.genericError,
      }}
      back={() => {}}
    ></MessageBox>
  );
};

export default PinActivationPage;

import { ClientService } from './clientApi';

class StationsApi {
  async searchNearbyStations(coords: {
    filter?: string;
    long?: number;
    lat?: number;
  }) {
    return ClientService.findNearbyDevices(coords).then((response) => {
      return response?.data?.data;
    });
  }

  searchStations(filter: string) {
    return [
      {
        name: 'Stazione 02',
        distance: 500,
      },
      {
        name: 'Stazione 01',
        distance: 1500,
      },
      {
        name: 'Stazione 03',
        distance: 3000,
      },
    ];
  }

  getDetail(id: string, query?: any) {
    return ClientService.getDetail(id, query).then((result) => {
      return result?.data;
    });
  }

  sendServiceActivationPIN(id: string, email: string, lang: string, data?: { time?: number}) {
    return ClientService.sendServiceActivationPIN(id, email, lang, data).then(
      (result) => {
        return result?.data;
      }
    );
  }
}

export const StationsService = new StationsApi();

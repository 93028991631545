import { ls } from "@i18n/translations";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import React from "react";

function ServiceSelection(
  props: React.PropsWithoutRef<{
    className?: string;
    options: {
      key: string | number;
      value: any;
      orderField?: any;
    }[];
    selectedValue?: any;
    title?: string;
    emptySelectionText?: string;
    placeholder?: string;
    hintText?: string;
    orderBy?: string;
    onChange: (event: any) => void;
  }>
) {
  const {
    selectedValue,
    title,
    placeholder,
    emptySelectionText,
    hintText,
    options,
    orderBy,
    onChange,
  } = props;

  let sortedOpt = options?.sort((a, b) => {
    if (!!orderBy) {
      return a.orderField < b.orderField
        ? -1
        : a.orderField > b.orderField
        ? 1
        : 0;
    }
    return a.value < b.value ? -1 : a.value > b.value ? 1 : 0;
  });

  let _selected = selectedValue;
  if ((_selected === "undefined" || !_selected) && sortedOpt.length == 1) {
    _selected = sortedOpt[0].key;

    setTimeout(() => {
      onChange &&
        onChange({
          target: {
            value: _selected,
          },
        });
    }, 100);
  }
  return (
    <FormControl>
      <InputLabel shrink id="label-id">
        {title}
      </InputLabel>
      <Select
        labelId="label-id"
        id="service-select"
        value={_selected}
        onChange={(event) => {
          onChange && onChange(event);
        }}
        displayEmpty
      >
        <MenuItem key="opt-none" value="">
          <em>{emptySelectionText}</em>
        </MenuItem>
        {sortedOpt.map((opt, idx) => {
          return (
            <MenuItem key={"opt-" + idx} value={opt.key}>
              {opt.value}
            </MenuItem>
          );
        })}
      </Select>
      <FormHelperText>{hintText}</FormHelperText>
    </FormControl>
  );
}

export default ServiceSelection;

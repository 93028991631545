import StyledRadio from "@components/StyledRadio";
import { ls } from "@i18n/translations";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import React, { Component, useState } from "react";
import { getSocketShortName, ValidateEmail } from "src/utils";

function SimpleChargingStation(
  props: React.PropsWithoutRef<{
    className?: string;
    data?: { station: any; stationService: any };
    select: (event: any) => void;
    confirm: () => void;
    goBack: () => void;
  }>
) {
  const { data, select, confirm, goBack } = props;
  let station = data?.station;
  let stationService = data?.stationService;
  console.log("stationService", stationService);
  let hasPayment = !!station?.config?.paymentRequired;
  const [service, setService] = useState(stationService);
  const [email, setEmail] = useState("");
  let emailError = "";
  if (email && !ValidateEmail(email)) {
    emailError = ls.emailNotValid;
  }
  // console.log('STATION SERVICE', stationService);
  if (!stationService) {
    // Pre seleziono la prima presa disponibile
    let firstAvailableSocket = station?.sockets?.find(
      (socket: any) => !!socket.online && !!socket.active && !socket.checkout
    );
    // console.log('selecting first socket');
    setTimeout(() => {
      select(firstAvailableSocket);
    }, 500);
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      flexGrow="1"
      flex="1"
      justifyContent="center"
      alignItems="center"
    >
      <Box mb={4}>
        <Typography
          align="center"
          variant="h3"
          gutterBottom
          style={{
            fontSize: "2.2rem",
          }}
        >
          {station.name}
        </Typography>
        <Typography
          variant="caption"
          style={{ textAlign: "center" }}
          display="block"
          gutterBottom
        >
          {ls.areYouThereOrHere}
        </Typography>
        <Typography
          variant="button"
          style={{ textAlign: "center" }}
          display="block"
          gutterBottom
          onClick={() => {
            goBack();
          }}
        >
          {ls.goBack}
        </Typography>
      </Box>

      <Box
        mt={3}
        mb={2}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="body1" gutterBottom className="text-center">
          {ls.chargingPoint}
        </Typography>
        <Typography variant="h6" gutterBottom className="text-center">
          {station.address || station.name}
        </Typography>
      </Box>

      <FormControl component="fieldset">
        <FormLabel
          style={{ marginBottom: 3, textAlign: "center" }}
          component="legend"
        >
          {ls.chooseTheSocket}
        </FormLabel>
        <RadioGroup
          aria-label="socket"
          name="socket"
          style={{
            marginLeft: 40,
          }}
          value={"" + service?.serviceIdx}
          onChange={(event) => {
            let value = event?.target?.value;
            // console.log('VALUE', value);
            let selected = station?.sockets.find((srv: any) => {
              return parseInt(srv.serviceIdx) === parseInt(value);
            });
            setService(selected);
            select(selected);
          }}
        >
          {station?.sockets.map((socket: any) => {
            return (
              <FormControlLabel
                value={"" + socket.serviceIdx}
                label={""}
                control={
                  <StyledRadio
                    label={getSocketShortName(`${socket.serviceIdx}`)}
                    status={
                      ((!!socket.checkout && "checkout") as string) ||
                      (((!socket.active || !!socket.status) &&
                        "notAvailable") as string)
                    }
                    statusMessage={
                      (((!!socket.checkout || !!socket.status) &&
                        ls.serviceCheckedOut) as string) ||
                      ((!socket.active && ls.notAvailable) as string)
                    }
                  />
                }
                disabled={
                  !!socket.checkout || !socket.active || !!socket.status
                }
              />
            );
          })}
        </RadioGroup>
      </FormControl>

      <Box mt={3}>
        <Button
          variant="contained"
          size="large"
          disabled={!stationService}
          color="primary"
          onClick={() => {
            confirm();
          }}
          fullWidth
        >
          {ls.recharge}
        </Button>
      </Box>
    </Box>
  );
}

export default SimpleChargingStation;

import { ls } from '@i18n/translations';
import { FormControl, Input, InputLabel } from '@material-ui/core';
import React from 'react';

function AdditionalInfo(
  props: React.PropsWithoutRef<{
    className?: string;
    formData: any;
    additionalInfo: any;
    updateFormData: (data: any) => void;
  }>
) {
  const { formData, additionalInfo, updateFormData } = props;

  let langs = ls as any;

  // console.log('ADDITIONAL INFO', additionalInfo);

  return Object.keys(additionalInfo).map((infoKey: string) => {
    let info = additionalInfo[infoKey];
    let value =
      formData?.additionalInfo &&
      formData?.additionalInfo[info.key] &&
      formData?.additionalInfo[info.key].value;
    // console.log('langs[`${info.label}`]', langs[`${info.label}`]);
    // console.log('error', info.required, !value);
    return (
      <FormControl style={{ marginBottom: 10 }}>
        <InputLabel shrink id='label-id' style={{ marginBottom: 4 }}>
          {langs[`${info.label}`]}
        </InputLabel>
        <Input
          error={info.required && !value}
          className='slim'
          style={{
            marginTop: 20,
          }}
          value={value}
          fullWidth
          type={info.type}
          placeholder={langs[`${info.label}`]}
          required={info.required}
          onChange={(event: any) => {
            info.value = event?.target?.value;
            updateFormData({ [info.key]: { ...info } });
          }}
        ></Input>
        {/* <FormHelperText>{hintText}</FormHelperText> */}
      </FormControl>
    );
  });
}

export default AdditionalInfo;

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { createMuiTheme, CssBaseline, ThemeProvider } from '@material-ui/core';
import { MontserratConfig } from './fontConfig';
import { BrowserRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { skipWaiting } from "workbox-core";

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      '"Montserrat"',
      '-apple-system',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
    ].join(','),
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [MontserratConfig as any],
      },
    },
  },
  palette: {
    type: 'dark',
    background: {
      default: '#2a2b2f',
    },
    text: {
      secondary: 'rgba(255, 255, 255, 0.5)',
    },
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#56c26d',
      contrastText: '#fff',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: 'rgba(255, 255, 255, 0.5)',
      contrastText: '#fff',
      // // dark: will be calculated from palette.secondary.main,
      // contrastText: '#ffcc00',
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <BrowserRouter>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <App />
    </BrowserRouter>
  </ThemeProvider>,
  document.querySelector('#root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    console.log("Update found reloading...");
    setTimeout(() => {
      skipWaiting();
      setTimeout(() => {
        location.reload(); //eslint-disable-line
      }, 500);
    }, 500);
  },
});

const history = createBrowserHistory(); // from 'history' node package
history.listen(() => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistration().then((reg) => {
      if (!reg) {
        return null;
      }

      reg.update();
    });
  }
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
